.new {
  width: 100%;
  display: flex;
}

.new .newContainer {
  flex: 1;
  margin-left: 246px;
}

.new .newContainer .top,
.new .newContainer .bottom {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
  margin: 20px;
  display: flex;
}

.new .newContainer .top h1.title,
.new .newContainer .bottom h1.title {
  color: lightgray;
  font-size: 20px;
}

.new .newContainer .top .left,
.new .newContainer .bottom .left {
  flex: 1;
  text-align: center;
}

.new .newContainer .top .left img,
.new .newContainer .bottom .left img {
  width: 90%;
  height: 300px;
  object-fit: cover;
}

.new .newContainer .top .right,
.new .newContainer .bottom .right {
  flex: 2;
}

.new .newContainer .top .right form,
.new .newContainer .bottom .right form {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  /* justify-content: space-around; */
}

.new .newContainer .top .right form .formInput,
.new .newContainer .bottom .right form .formInput {
  width: 40%;
}

.new .newContainer .top .right form .formInput label,
.new .newContainer .bottom .right form .formInput label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.new .newContainer .top .right form .formInput label .icon,
.new .newContainer .bottom .right form .formInput label .icon {
  cursor: pointer;
}

.new .newContainer .top .right form .formInput input,
.new .newContainer .bottom .right form .formInput input,
.new .newContainer .top .right form .formInput select,
.new .newContainer .bottom .right form .formInput select {
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 1px solid gray;
}

.new .newContainer .top .right form button[type="submit"],
.new .newContainer .bottom .right form button[type="submit"] {
  width: 150px;
  padding: 10px;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}

.new .newContainer .right .errorMsg {
  color: red;
  font-style: italic;
}

/* Container for checkbox */
.new .newContainer .checkboxInput {
  width: 40%;
  display: flex;
  align-items: center;
}

/* Label for checkbox */
.new .newContainer .checkboxInput .checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 30px; /* Space for custom checkbox */
  font-size: 14px;
  color: #555;
}

/* Hide default checkbox */
.new .newContainer .checkboxInput .checkboxLabel input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Custom checkbox design */
.new .newContainer .checkboxInput .checkboxLabel .customCheckbox {
  position: absolute;
  left: 0;
  top: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border: 2px solid #6439ff;
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.2s, border-color 0.2s;
}

/* Checkbox hover effect */
.new .newContainer .checkboxInput .checkboxLabel:hover .customCheckbox {
  border-color: #5328d6;
}

/* Checkbox checked state */
.new
  .newContainer
  .checkboxInput
  .checkboxLabel
  input:checked
  + .customCheckbox {
  background-color: #6439ff;
  border-color: #6439ff;
}

/* Add a checkmark for checked state */
.new .newContainer .checkboxInput .checkboxLabel .customCheckbox::after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Show checkmark when checked */
.new
  .newContainer
  .checkboxInput
  .checkboxLabel
  input:checked
  + .customCheckbox::after {
  display: block;
}

.new p {
  color: black;
}

/* new.css */

/* Tabs container (the row of buttons) */
.editorTabs {
  margin: 1rem 0;
  display: flex;
  gap: 0.5rem;
}

.tabButton {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  cursor: pointer;
  border-radius: 4px;
}

.tabButton.activeTab {
  background-color: #ddd;
  font-weight: bold;
}

/* The container for the actual tab content */
.tabContent {
  margin: 1rem 0;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 4px;
  background-color: #fff;
}

/* Quill editor wrapper (optional for extra styling/margins) */
.editorWrapper {
  margin-bottom: 1rem;
}

/* Example for error messages below the editor */
.errorMsg {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: inline-block;
}
