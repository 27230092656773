@charset "UTF-8";
/**
 * Mixin
 */
@-webkit-keyframes rotating4 {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating4 {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes animation4 {
  from {
    left: -40%;
    width: 40%;
  }
  to {
    left: 100%;
    width: 10%;
  }
}

@keyframes animation4 {
  from {
    left: -40%;
    width: 40%;
  }
  to {
    left: 100%;
    width: 10%;
  }
}

.learn-press-message {
  position: relative;
  margin: 0 0 20px 0;
  padding: 10px 12px 10px 15px;
  border-top: 5px solid #00adff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #f5f5f5;
}

.learn-press-message.icon {
  padding-left: 45px;
}

.learn-press-message.icon::before {
  background: #00adff;
}

.learn-press-message.icon::after {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 26px;
  height: 26px;
  margin-top: -13px;
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
  background: #00adff;
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  vertical-align: baseline;
  content: "\f129";
}

.learn-press-message::before,
.learn-press-message::after {
  position: absolute;
  top: 0;
  content: "";
}

.learn-press-message::before {
  right: 20px;
  width: 20px;
  height: 30px;
  background: #00adff;
}

.learn-press-message::after {
  top: 10px;
  right: 20px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #f5f5f5;
}

.learn-press-message.error {
  border-top: 5px solid #d85554;
}

.learn-press-message.error::before {
  background: #d85554;
}

.learn-press-message.error.icon::before {
  background: #d85554;
}

.learn-press-message.error.icon::after {
  background: #d85554;
  content: "\f00d";
}

.learn-press-message.warning {
  border-top: 5px solid #ffc107;
}

.learn-press-message.warning::before {
  background: #ffc107;
}

.learn-press-message.warning.icon::before {
  background: #ffc107;
}

.learn-press-message.warning.icon::after {
  background: #ffc107;
  content: "\f00d";
}

.learn-press-message.success {
  border-top: 5px solid #059601;
}

.learn-press-message.success::before {
  background: #059601;
}

.learn-press-message.success.icon::before {
  background: #059601;
}

.learn-press-message.success.icon::after {
  background: #059601;
  content: "\f129";
}

@keyframes lp-rotating {
  from {
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lp-rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ajaxload {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  background: #7b7b7b;
  content: "";
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: lp-rotating 1s linear infinite;
  -moz-animation: lp-rotating 1s linear infinite;
  animation: lp-rotating 1s linear infinite;
}

.ajaxload::after {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background: #dedede;
  content: "";
}

.lp-ajaxload-style-1 {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}

.lp-ajaxload-style-1::before,
.lp-ajaxload-style-1::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 3px solid #ddd;
  content: "";
}

.lp-ajaxload-style-1::after {
  clip: rect(0, 32px, 16px, 16px);
  border-color: #2fa7ff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: lp-rotating 1s linear infinite;
  -moz-animation: lp-rotating 1s linear infinite;
  animation: lp-rotating 1s linear infinite;
}

.learn-press-tabs {
  margin-bottom: 34px;
  background: #f1f2f8;
}

.learn-press-tabs .learn-press-tabs__checker {
  display: none;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(1):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(1) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(1):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(1)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(1):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(1)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(1):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(1)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(1):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(1)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(2):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(2) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(2):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(2)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(2):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(2)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(2):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(2)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(2):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(2)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(3):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(3) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(3):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(3)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(3):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(3)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(3):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(3)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(3):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(3)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(4):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(4) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(4):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(4)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(4):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(4)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(4):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(4)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(4):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(4)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(5):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(5) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(5):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(5)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(5):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(5)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(5):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(5)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(5):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(5)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(6):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(6) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(6):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(6)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(6):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(6)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(6):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(6)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(6):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(6)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(7):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(7) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(7):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(7)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(7):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(7)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(7):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(7)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(7):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(7)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(8):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(8) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(8):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(8)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(8):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(8)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(8):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(8)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(8):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(8)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(9):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(9) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(9):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(9)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(9):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(9)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(9):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(9)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(9):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(9)::after {
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(10):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(10) {
  border-bottom: 0;
  background: #fff;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(10):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(10)
  label {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(10):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(10)
  label
  a {
  color: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(10):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(10)::before {
  background: #ffb606;
}

.learn-press-tabs
  .learn-press-tabs__checker:nth-child(10):checked
  ~ .learn-press-tabs__nav
  .learn-press-tabs__tab:nth-child(10)::after {
  background: #fff;
}

.learn-press-tabs .learn-press-tabs__nav {
  display: flex;
  margin: 0;
  padding: 0;
  border: 1px solid #e5e7f2;
  list-style: none;
}

.learn-press-tabs .learn-press-tabs__tab {
  position: relative;
  margin: 0;
  border-right: 1px solid #e5e7f2;
  background: #f1f2f8;
  list-style: none;
}

.learn-press-tabs .learn-press-tabs__tab:last-child {
  border-right: 0;
}

.learn-press-tabs .learn-press-tabs__tab > label {
  display: block;
  margin: 0;
  padding: 20px 65px 14px 65px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
}

.learn-press-tabs .learn-press-tabs__tab > label a {
  color: #333;
}

.learn-press-tabs .learn-press-tabs__tab > label a:focus {
  outline: 0;
  text-decoration: none;
}

@media (max-width: 767px) {
  .learn-press-tabs .learn-press-tabs__tab > label {
    padding: 20px 50px 14px 50px;
  }
}

.learn-press-tabs .learn-press-tabs__tab::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 4px;
  background: transparent;
  content: "";
}

.learn-press-tabs .learn-press-tabs__tab::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 4px;
  background: transparent;
  content: "";
}

.learn-press-tabs .learn-press-tabs__tab:first-child.active label {
  position: relative;
}

.learn-press-tabs .learn-press-tabs__tab:first-child.active label::before {
  position: absolute;
  top: 0;
  left: -1px;
  width: 1px;
  height: 100%;
  background: #fff;
  content: "";
}

.learn-press-tabs.stretch .learn-press-tabs__tab {
  flex: 1;
}

.learn-press-tabs.stretch .learn-press-tabs__tab > label {
  padding: 18px 10px;
}

.learn-press-filters {
  display: flex;
  margin-bottom: 36px;
  margin-left: 0;
  list-style: none;
}

@media (max-width: 767px) {
  .learn-press-filters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
}

.learn-press-filters > li {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

.learn-press-filters > li > a,
.learn-press-filters > li > span {
  display: inline-block;
  font-weight: 600;
}

.learn-press-filters > li > span.count {
  font-weight: 400;
}

.learn-press-filters > li > span.count::before,
.learn-press-filters > li > span.count::after {
  display: inline-block;
}

.learn-press-filters > li > span.count::before {
  content: "(";
}

.learn-press-filters > li > span.count::after {
  content: ")";
}

.learn-press-filters > li span {
  color: #ffb606;
}

.learn-press-filters > li span + span {
  color: #ffb606;
}

.learn-press-filters > li span + span::before,
.learn-press-filters > li span + span::after {
  color: #ffb606;
}

.learn-press-filters > li a {
  color: #333;
}

.learn-press-filters > li a + span {
  color: #333;
}

.learn-press-filters > li a + span::before,
.learn-press-filters > li a + span::after {
  color: #333;
}

.learn-press-filters > li::after {
  margin: 0 35px;
  color: #ccc;
  content: "|";
}

@media (max-width: 767px) {
  .learn-press-filters > li::after {
    display: none;
  }
}

.learn-press-filters > li:last-child::after {
  display: none;
}

.lp-checkout-form__before,
.lp-checkout-form__after {
  width: 45%;
  width: -webkit-calc(50% - 30px);
  width: -moz-calc(50% - 30px);
  width: calc(50% - 30px);
  margin: 0 15px 40px 15px;
}

.lp-checkout-form__before .lp-checkout-block h4,
.lp-checkout-form__after .lp-checkout-block h4 {
  margin-top: 0;
  margin-bottom: 18px;
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

@media (max-width: 815px) {
  .lp-checkout-form__before,
  .lp-checkout-form__after {
    width: 100%;
    margin: 0;
  }
}

.lp-checkout-form__before {
  float: right;
}

.lp-checkout-form__before #checkout-order {
  width: 100%;
}

#learn-press-checkout-form .lp-checkout-form__after {
  float: left;
  margin-bottom: 0;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields {
  padding: 0 0 14px 0;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields
  .form-field
  .rwmb-input
  input:not([type="checkbox"]) {
  width: 100%;
  height: 43px;
  padding: 0 22px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  line-height: 43px;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields
  .form-field
  .rwmb-input
  input:not([type="checkbox"]):focus {
  border-color: var(--lp-primary-color);
  outline: 0;
  color: #222;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields
  .form-field
  .rwmb-input
  ::placeholder {
  opacity: 1;
  color: #999;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields
  .form-field
  .rwmb-input
  :-ms-input-placeholder {
  color: #999;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields
  .form-field
  .rwmb-input
  ::-ms-input-placeholder {
  color: #999;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields
  .form-field
  .rwmb-input
  input:-webkit-autofill,
#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields
  .form-field
  .rwmb-input
  input:-webkit-autofill:hover,
#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-form-fields
  .form-field
  .rwmb-input
  input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  -webkit-text-fill-color: #999 !important;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-checkout-remember {
  letter-spacing: 0;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-checkout-remember
  label {
  width: auto;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-checkout-remember
  label
  input[type="checkbox"] {
  position: relative;
  top: 3px;
  width: 19px;
  height: 19px;
  margin: 0 4px 0 0;
  border: 1px solid #ccc;
  cursor: pointer;
  -webkit-appearance: none;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-checkout-remember
  label
  input[type="checkbox"]:focus {
  outline: 0;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-checkout-remember
  label
  input[type="checkbox"]:checked::after {
  position: absolute;
  top: 2px;
  left: 2px;
  color: #41abec;
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 900;
  line-height: 1;
  content: "\f00c";
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-checkout-remember
  a {
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  text-decoration: underline;
  text-decoration-color: #ccc;
}

#learn-press-checkout-form
  .lp-checkout-form__after
  .lp-checkout-block
  .lp-checkout-remember
  a:hover {
  color: var(--lp-primary-color);
  text-decoration-color: var(--lp-primary-color);
}

#learn-press-checkout-form {
  margin: 0 -15px;
}

@media (max-width: 815px) {
  #learn-press-checkout-form {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
  }
}

#learn-press-checkout-form #btn-checkout-account-switch-to-guest {
  margin-bottom: 60px;
}

#learn-press-checkout-form .description {
  float: left;
}

#learn-press-checkout-form a {
  color: #41abec;
}

#learn-press-checkout-form a label {
  display: inline-block;
  color: inherit;
  font-weight: normal;
  cursor: pointer;
}

#learn-press-checkout-form a:hover {
  color: #666;
}

#learn-press-checkout-form #checkout-account-guest {
  width: 100%;
  margin-bottom: 35px;
  padding-bottom: 19px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
}

#learn-press-checkout-form #checkout-account-guest .lp-form-fields,
#learn-press-checkout-form #checkout-account-guest .form-field {
  margin-bottom: 0;
  padding-bottom: 0;
}

#learn-press-checkout-form div.lp-guest-checkout-output {
  margin-top: 10px;
  padding: 8px 20px;
  border: 2px solid #41abec;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.2s ease;
}

#learn-press-checkout-form label.lp-guest-checkout-output {
  margin-top: 10px;
  cursor: pointer;
  user-select: none;
}

#learn-press-checkout-form .lp-guest-checkout-notice {
  margin: 30px 0 0 0;
}

#learn-press-checkout-form .lp-guest-switch-login {
  margin-top: 1em;
  color: #666;
  font-size: 16px;
  font-weight: 400;
}

#learn-press-checkout-form input[name="checkout-account-switch-form"] {
  display: none;
}

#learn-press-checkout-form
  input[name="checkout-account-switch-form"]:not(:checked)
  + .lp-checkout-block {
  display: none;
  overflow: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  opacity: 0;
}

#learn-press-checkout-form
  input[name="checkout-account-switch-form"]:not(:checked)
  + .lp-checkout-block
  > * {
  overflow: hidden;
  height: 0;
}

#learn-press-checkout-form
  input[name="checkout-account-switch-form"]:checked
  + .lp-checkout-block {
  display: block;
  overflow: auto;
  height: auto;
}

#learn-press-checkout-form
  input[name="checkout-account-switch-form"][value="guest"]:checked
  ~ #btn-checkout-account-switch-to-guest {
  display: none;
}

#learn-press-checkout-form::after {
  display: block;
  clear: both;
  content: "";
}

#learn-press-checkout-form:focus {
  outline: 0;
}

#checkout-order .lp-checkout-order__inner {
  padding: 0 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#checkout-order .lp-checkout-order__inner table {
  width: 100%;
  margin: 0;
  border: 0;
}

#checkout-order .lp-checkout-order__inner td,
#checkout-order .lp-checkout-order__inner th {
  box-sizing: content-box;
  border: 1px solid rgba(204, 204, 204, 0.3);
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background: unset;
  line-height: 1;
  text-align: left;
  vertical-align: inherit;
}

#checkout-order .lp-checkout-order__inner td:last-child,
#checkout-order .lp-checkout-order__inner th:last-child {
  padding-right: 0;
}

#checkout-order .lp-checkout-order__inner .course-thumbnail {
  width: 100px;
  padding: 20px 13px 18px 0;
}

#checkout-order .lp-checkout-order__inner .course-thumbnail > img {
  width: 100px;
  max-width: 100% !important;
  height: auto !important;
}

#checkout-order .lp-checkout-order__inner .course-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

#checkout-order .lp-checkout-order__inner .course-name a {
  display: -webkit-box;
  overflow: hidden;
  color: #666;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#checkout-order .lp-checkout-order__inner .course-name a:hover {
  color: var(--lp-primary-color);
}

#checkout-order .lp-checkout-order__inner .col-number {
  min-width: 50px;
  color: #666;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: right;
}

#checkout-order .lp-checkout-order__inner .order-total > th {
  padding: 24px 20px 24px 0;
}

#checkout-order .lp-checkout-order__inner .order-total .col-number {
  color: #333;
  font-size: 26px;
}

#checkout-order .lp-checkout-order__inner tfoot .cart-subtotal {
  color: #666;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

#checkout-order .lp-checkout-order__inner tfoot .cart-subtotal > th {
  padding: 20px 20px 17px 0;
}

#checkout-order .lp-checkout-order__inner tfoot .order-total th {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

#checkout-order .lp-checkout-order__inner tfoot tr:last-child {
  border-width: 0;
}

#checkout-order .lp-checkout-order__inner tfoot tr:last-child td,
#checkout-order .lp-checkout-order__inner tfoot tr:last-child th {
  border-width: 0;
}

#checkout-account-register,
#checkout-account-login {
  width: 100%;
  margin-bottom: 35px;
  padding-bottom: 19px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
}

#checkout-account-register .form-field .rwmb-label,
#checkout-account-register .form-field .rwmb-input .description,
#checkout-account-login .form-field .rwmb-label,
#checkout-account-login .form-field .rwmb-input .description {
  display: none;
}

#checkout-account-register .lp-checkout-remember label,
#checkout-account-login .lp-checkout-remember label {
  display: inline-block;
  /*font-weight: normal;
            color: inherit;*/
}

#checkout-account-register .lp-checkout-remember a,
#checkout-account-login .lp-checkout-remember a {
  float: right;
}

#checkout-account-register .lp-checkout-sign-in-link,
#checkout-account-register .lp-checkout-sign-up-link,
#checkout-account-login .lp-checkout-sign-in-link,
#checkout-account-login .lp-checkout-sign-up-link {
  display: flex;
  margin: 0;
  color: #666;
  font-size: 16px;
  font-weight: 400;
}

#checkout-account-register .lp-checkout-sign-in-link a,
#checkout-account-register .lp-checkout-sign-up-link a,
#checkout-account-login .lp-checkout-sign-in-link a,
#checkout-account-login .lp-checkout-sign-up-link a {
  margin-left: 5px;
  color: #41abec;
  cursor: pointer;
}

#checkout-account-register .lp-checkout-sign-in-link a:hover,
#checkout-account-register .lp-checkout-sign-up-link a:hover,
#checkout-account-login .lp-checkout-sign-in-link a:hover,
#checkout-account-login .lp-checkout-sign-up-link a:hover {
  color: var(--lp-primary-color);
}

#checkout-account-register .lp-checkout-sign-in-link a label,
#checkout-account-register .lp-checkout-sign-up-link a label,
#checkout-account-login .lp-checkout-sign-in-link a label,
#checkout-account-login .lp-checkout-sign-up-link a label {
  display: inline-block;
  float: right;
  color: inherit;
  font-weight: normal;
  cursor: pointer;
}

#checkout-payment {
  width: 100%;
  margin-bottom: 25px;
}

#checkout-payment h4 {
  margin-bottom: 24px;
}

#checkout-payment .secure-connection {
  float: right;
  margin-top: 5px;
  color: #999;
  font-size: 12px;
  font-weight: normal;
}

#checkout-payment .secure-connection i {
  margin-right: 5px;
  color: #ccc;
  font-size: 18px;
}

@media (max-width: 767px) {
  #checkout-payment .secure-connection {
    margin-top: 0;
  }
}

#checkout-payment .payment-methods {
  margin: 0 0 24px 0;
  padding: 0;
  list-style: none;
}

#checkout-payment .payment-methods .lp-payment-method {
  position: relative;
  margin: 0;
}

#checkout-payment .payment-methods .lp-payment-method > label {
  display: flex;
  align-items: center;
}

#checkout-payment .payment-methods .lp-payment-method .gateway-input,
#checkout-payment .payment-methods .lp-payment-method .gateway-icon {
  vertical-align: middle;
}

#checkout-payment .payment-methods .lp-payment-method .gateway-icon {
  max-height: 32px;
}

#checkout-payment .payment-methods .lp-payment-method .gateway-input {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 8px 0 0;
  border: 1px solid #ccc;
  background: #fff;
  -webkit-appearance: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#checkout-payment .payment-methods .lp-payment-method .gateway-input::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  background: #41abec;
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#checkout-payment
  .payment-methods
  .lp-payment-method
  .gateway-input:checked::before {
  content: "";
}

#checkout-payment .payment-methods .lp-payment-method .gateway-input:focus {
  outline: 0;
}

#checkout-payment #checkout-order-action button {
  width: 100%;
  border-color: var(--lp-primary-color);
  color: #fff;
  background: var(--lp-primary-color);
  font-weight: 500;
}

#checkout-payment #checkout-order-action button:hover {
  border-color: var(--lp-primary-color);
  opacity: 0.5;
  background: var(--lp-primary-color);
}

#checkout-payment #checkout-order-action button.loading::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f110";
  -webkit-animation: lp-rotating 1s linear infinite;
  -moz-animation: lp-rotating 1s linear infinite;
  animation: lp-rotating 1s linear infinite;
}

.lp-terms-and-conditions {
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

.lp-terms-and-conditions a {
  color: #41abec;
}

.lp-terms-and-conditions a:hover {
  color: var(--lp-primary-color);
}

.learn-press-checkout.guest-checkout {
  display: none;
}

.button-continue-guest-checkout {
  clear: both;
}

#learn-press-checkout .payment-methods {
  margin: 0;
  padding: 0;
  list-style: none;
}

#learn-press-checkout .payment-methods .lp-payment-method {
  margin-bottom: 20px;
}

#learn-press-checkout .payment-methods .lp-payment-method > label {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 20px;
  background: #f5f5f5;
  line-height: 40px;
  cursor: pointer;
}

#learn-press-checkout .payment-methods .lp-payment-method > label img {
  vertical-align: middle;
}

#learn-press-checkout .payment-methods .lp-payment-method.selected > label {
  background: #d4d4d4;
}

#learn-press-checkout .payment-methods .payment-method-form {
  display: none;
  padding: 15px 20px;
  border-top: 1px solid #ddd;
  background: #f9f9f9;
}

#learn-press-checkout-login,
#learn-press-checkout-register {
  margin-bottom: 1.5em;
  padding: 20px 20px 0 20px;
  border: 1px solid #ddd;
  background: #fff;
}

#learn-press-order-review,
.learn-press-checkout-comment {
  margin-bottom: 20px;
}

#checkout-form-login,
#checkout-form-register {
  _display: none;
}

#checkout-form-login .learn-press-form-register,
#checkout-form-login .learn-press-form-login,
#checkout-form-register .learn-press-form-register,
#checkout-form-register .learn-press-form-login {
  display: none;
}

#checkout-guest-email {
  margin: 0 0 20px 0;
}

#checkout-guest-email .form-heading {
  margin: 0;
}

#checkout-guest-email #checkout-guest-options {
  margin: 0;
  list-style: none;
}

#checkout-guest-email #checkout-existing-account,
#checkout-guest-email #checkout-new-account {
  display: none;
  margin: 0;
}

#checkout-guest-email.email-exists #checkout-existing-account {
  display: block;
}

#checkout-guest-email.email-exists #checkout-new-account {
  display: none;
}

.lp-list-table {
  width: 100%;
  margin-bottom: 20px;
}

.lp-list-table th,
.lp-list-table td {
  padding: 20px;
  border: 0 solid rgba(204, 204, 204, 0.3);
  border-bottom-width: 1px;
  background: #fff;
  font-size: 16px;
  line-height: 1;
  text-align: left;
}

@media (max-width: 767px) {
  .lp-list-table th,
  .lp-list-table td {
    padding: 15px;
  }
}

.lp-list-table thead {
  box-sizing: border-box;
  border: 1px solid #e5e7f2;
}

.lp-list-table thead tr th {
  height: 60px;
  border-bottom: none;
  color: #333;
  background: #f1f2f8;
  font-size: 18px;
}

.lp-list-table tbody,
.lp-list-table tfoot {
  box-sizing: border-box;
  border: 1px solid rgba(204, 204, 204, 0.3);
  border-top: 0;
}

.lp-list-table tbody tr td,
.lp-list-table tbody tr th,
.lp-list-table tfoot tr td,
.lp-list-table tfoot tr th {
  height: 62px;
  color: #666;
  background: #fff;
  font-size: 16px;
  font-weight: 300;
}

.lp-list-table tbody tr td a,
.lp-list-table tbody tr th a,
.lp-list-table tfoot tr td a,
.lp-list-table tfoot tr th a {
  border-bottom: none;
  color: #666;
  text-decoration: none;
}

.lp-list-table tbody tr td a:hover,
.lp-list-table tbody tr th a:hover,
.lp-list-table tfoot tr td a:hover,
.lp-list-table tfoot tr th a:hover {
  color: var(--lp-primary-color);
}

.lp-list-table tbody tr .column-status .result-percent,
.lp-list-table tfoot tr .column-status .result-percent {
  font-weight: 500;
}

.lp-list-table tbody tr .column-status .lp-label,
.lp-list-table tfoot tr .column-status .lp-label {
  font-weight: 600;
}

.lp-list-table tbody tr:nth-child(odd),
.lp-list-table tfoot tr:nth-child(odd) {
  background: #f5f5f5;
}

.lp-list-table .list-table-nav td {
  font-size: 14px;
}

.lp-list-table .list-table-nav td.nav-text {
  text-align: left;
}

.lp-list-table .list-table-nav td.nav-pages {
  text-align: right;
}

.lp-list-table .list-table-nav td.nav-pages .learn-press-pagination {
  text-align: right;
}

.lp-list-table .list-table-nav td.nav-pages .page-numbers {
  margin-bottom: 0;
}

.lp-label {
  display: inline-block;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
}

.learn-press-form .form-fields {
  margin: 0;
  padding: 0;
  list-style: none;
}

.learn-press-form .form-fields .form-field {
  margin: 0 0 20px 0;
}

.learn-press-form .form-fields .form-field label {
  display: block;
  margin: 0 0 10px 0;
}

.learn-press-form .form-fields .form-field input[type="text"],
.learn-press-form .form-fields .form-field input[type="email"],
.learn-press-form .form-fields .form-field input[type="number"],
.learn-press-form .form-fields .form-field input[type="password"],
.learn-press-form .form-fields .form-field textarea {
  width: 100%;
  padding: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.learn-press-form .form-fields .form-field .description {
  margin-top: 10px;
  font-size: 14px;
  font-style: italic;
  line-height: 1.4;
}

.learn-press-form .form-fields .form-field .asterisk {
  color: #f00;
}

.learn-press-form-login,
.learn-press-form-register {
  max-width: 600px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  padding: 40px;
  border: 1px solid #eee;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.learn-press-form-login h3,
.learn-press-form-register h3 {
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 1.4;
}

.learn-press-form-login .form-fields .form-field label,
.learn-press-form-register .form-fields .form-field label {
  font-size: 16px;
  font-weight: 400;
  user-select: none;
}

.learn-press-form-login .form-fields .form-field input[type="text"],
.learn-press-form-login .form-fields .form-field input[type="password"],
.learn-press-form-login .form-fields .form-field input[type="tel"],
.learn-press-form-login .form-fields .form-field input[type="url"],
.learn-press-form-login .form-fields .form-field input[type="number"],
.learn-press-form-register .form-fields .form-field input[type="text"],
.learn-press-form-register .form-fields .form-field input[type="password"],
.learn-press-form-register .form-fields .form-field input[type="tel"],
.learn-press-form-register .form-fields .form-field input[type="url"],
.learn-press-form-register .form-fields .form-field input[type="number"] {
  height: 42px;
  border: 1px solid #bbb;
  border-radius: 4px;
  line-height: 42px;
}

.learn-press-form-login .form-fields .form-field input[type="text"]:focus,
.learn-press-form-login .form-fields .form-field input[type="password"]:focus,
.learn-press-form-login .form-fields .form-field input[type="tel"]:focus,
.learn-press-form-login .form-fields .form-field input[type="url"]:focus,
.learn-press-form-login .form-fields .form-field input[type="number"]:focus,
.learn-press-form-register .form-fields .form-field input[type="text"]:focus,
.learn-press-form-register
  .form-fields
  .form-field
  input[type="password"]:focus,
.learn-press-form-register .form-fields .form-field input[type="tel"]:focus,
.learn-press-form-register .form-fields .form-field input[type="url"]:focus,
.learn-press-form-register .form-fields .form-field input[type="number"]:focus {
  border: none;
  outline: 2px solid transparent !important;
  outline-offset: 2px;
  box-shadow: 0 0 0 2px #666 !important;
}

.learn-press-form-login .form-fields .form-field.required label::after,
.learn-press-form-register .form-fields .form-field.required label::after {
  content: " *";
  display: inline;
}

.learn-press-form-login form[name="learn-press-login"] > p > label,
.learn-press-form-register form[name="learn-press-login"] > p > label {
  color: #666;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.learn-press-form-login form[name="learn-press-login"] > p > a,
.learn-press-form-register form[name="learn-press-login"] > p > a {
  color: #666;
  font-size: 16px;
  line-height: 1;
}

.learn-press-form-login form[name="learn-press-login"] > p:last-child,
.learn-press-form-register form[name="learn-press-login"] > p:last-child {
  margin-bottom: 0;
}

.learn-press-form-login button[type="submit"],
.learn-press-form-register button[type="submit"] {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  outline: none;
  color: #fff;
  background: var(--lp-primary-color);
  box-shadow: none;
  font-size: 16px;
  line-height: 1;
  vertical-align: middle;
  text-decoration: none;
}

.required label {
  font-weight: bold;
}

.required label:after {
  content: " *";
  display: inline;
}

.lp-password-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.lp-password-input input[type="password"] {
  padding-right: 2.5rem;
}

.lp-password-input input::-ms-reveal {
  display: none;
}

.lp-password-input .lp-show-password-input {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
}

.lp-password-input .lp-show-password-input::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f06e";
}

.lp-password-input .lp-show-password-input.display-password::after {
  color: #585858;
  content: "\f070";
}

.form-desc {
  font-size: smaller;
  font-style: italic;
}

.become-teacher-form {
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
}

.become-teacher-form .form-field input[type="text"],
.become-teacher-form .form-field input[type="email"],
.become-teacher-form .form-field input[type="number"],
.become-teacher-form .form-field input[type="password"] {
  width: 100%;
}

body div.entry-content > div.learnpress {
  --responsive--aligndefault-width: 100%;
  max-width: var(--lp-content-width, 100%);
}

#learn-press-profile {
  overflow: hidden;
  position: relative;
  background: #fff;
}

#learn-press-profile .lp-user-profile-avatar img {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: unset;
}

#learn-press-profile .lp-user-profile-socials {
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: -17px;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#learn-press-profile .lp-user-profile-socials a {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 7px;
  border: 1px solid #cfcfcf;
  color: #666;
  background: #fff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#learn-press-profile .lp-user-profile-socials a:hover {
  border-color: var(--lp-primary-color);
  color: #fff;
  background: var(--lp-primary-color);
}

#learn-press-profile .wrapper-profile-header {
  color: #fff;
  background: var(--lp-secondary-color);
}

#learn-press-profile .wrapper-profile-header .lp-profile-content-area {
  position: relative;
  min-height: 345px;
  padding-top: 50px;
}

#learn-press-profile .wrapper-profile-header .lp-profile-left {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 270px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
  background: #fff;
}

#learn-press-profile .wrapper-profile-header .lp-profile-right {
  margin-left: 270px;
  padding-right: 15px;
  padding-left: 35px;
}

#learn-press-profile
  .wrapper-profile-header
  .lp-profile-right
  .lp-profile-username {
  padding-bottom: 18px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

#learn-press-profile
  .wrapper-profile-header
  .lp-profile-right
  .lp-profile-user-bio {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

@media (max-width: 990px) {
  #learn-press-profile .wrapper-profile-header .lp-profile-content-area {
    min-height: inherit;
    padding: 20px 15px;
  }
  #learn-press-profile .wrapper-profile-header .lp-profile-left {
    float: left;
    position: static;
  }
  #learn-press-profile .wrapper-profile-header .lp-profile-right {
    float: right;
    width: calc(100% - 270px);
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  #learn-press-profile .wrapper-profile-header .lp-profile-left {
    width: 220px;
  }
  #learn-press-profile .wrapper-profile-header .lp-profile-right {
    width: calc(100% - 220px);
  }
  #learn-press-profile .wrapper-profile-header .lp-user-profile-socials {
    margin-right: -5px;
    margin-left: -5px;
  }
  #learn-press-profile .wrapper-profile-header .lp-user-profile-socials a {
    width: 36px;
    height: 36px;
    margin: 0 5px;
    line-height: 36px;
  }
}

@media (max-width: 500px) {
  #learn-press-profile .wrapper-profile-header .lp-profile-left {
    float: none;
    margin: 0 auto;
  }
  #learn-press-profile .wrapper-profile-header .lp-profile-right {
    width: 100%;
    padding-top: 30px;
    text-align: center;
  }
}

#learn-press-profile #profile-sidebar {
  float: left;
  width: 270px;
  margin-top: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;
  background: #fff;
}

@media (max-width: 990px) {
  #learn-press-profile #profile-sidebar {
    width: 100%;
  }
  #learn-press-profile #profile-sidebar .lp-user-profile-avatar {
    max-width: 150px;
  }
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs {
  margin: 0;
  padding: 0;
  list-style: none;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li {
  position: relative;
  margin: 0;
  border-top: 1px solid rgba(204, 204, 204, 0.3);
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li a {
  display: block;
  padding: 0 20px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 53px;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li > a {
  padding: 0 20px 0 52px;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li > a > i {
  display: inline-block;
  position: absolute;
  left: 24px;
  width: 28px;
  color: var(--lp-primary-color);
  font-size: 12px;
  line-height: 52px;
  text-align: left;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li > a::after {
  float: right;
  margin-right: 4px;
  color: #999;
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  font-weight: 900;
  content: "\f054";
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li ul li {
  margin: 0;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li ul li a {
  border-bottom: 1px solid #f9f9f9;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li ul li a:hover {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li.active > a::after,
#learn-press-profile #profile-nav .lp-profile-nav-tabs > li:hover > a::after {
  content: "\f053";
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li.active a {
  padding-left: 52px;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#learn-press-profile
  #profile-nav
  .lp-profile-nav-tabs
  > li.wishlist
  > a::before {
  position: absolute;
  left: 25px;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 900;
  content: "\f004";
}

#learn-press-profile
  #profile-nav
  .lp-profile-nav-tabs
  > li.wishlist:hover
  a::before {
  color: #fff;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs > li.logout > a::after {
  display: none;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs li > a {
  box-sizing: border-box;
  height: 53px;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs li > ul {
  display: none;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 100%;
  min-width: 150px;
  margin: 0;
  padding: 0;
  background: #fff;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.05);
  list-style: none;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs li.active,
#learn-press-profile #profile-nav .lp-profile-nav-tabs li:hover {
  background: var(--lp-primary-color);
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs li.active > a,
#learn-press-profile #profile-nav .lp-profile-nav-tabs li:hover > a {
  color: #fff;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs li.active > a i,
#learn-press-profile #profile-nav .lp-profile-nav-tabs li.active > a::after,
#learn-press-profile #profile-nav .lp-profile-nav-tabs li:hover > a i,
#learn-press-profile #profile-nav .lp-profile-nav-tabs li:hover > a::after {
  color: #fff;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs li.active > ul,
#learn-press-profile #profile-nav .lp-profile-nav-tabs li:hover > ul {
  display: block;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs li.active > ul {
  position: relative;
  left: 0;
}

#learn-press-profile #profile-nav .lp-profile-nav-tabs li.active > ul .active {
  background: #fff;
}

#learn-press-profile
  #profile-nav
  .lp-profile-nav-tabs
  li.active
  > ul
  .active
  > a {
  color: #ffb606;
}

@media (max-width: 990px) {
  #learn-press-profile #profile-nav {
    overflow-x: scroll;
    border-top: 1px solid rgba(204, 204, 204, 0.3);
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs {
    width: max-content;
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs::after {
    display: table;
    clear: both;
    content: "";
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs > li {
    float: left;
    border: none;
    border-right: 1px solid rgba(204, 204, 204, 0.3);
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs > li > a {
    height: auto;
    padding: 0 20px 0 45px;
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs > li > a::after {
    margin-left: 10px;
  }
  #learn-press-profile
    #profile-nav
    .lp-profile-nav-tabs
    > li.has-child
    a::after {
    content: "\f077";
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs > li.active a {
    padding-left: 45px;
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs > li.active a::after {
    content: "\f078";
  }
  #learn-press-profile
    #profile-nav
    .lp-profile-nav-tabs
    > li.active.has-child
    ul {
    display: none;
    width: max-content;
    transform: translateX(-20%);
  }
  #learn-press-profile
    #profile-nav
    .lp-profile-nav-tabs
    > li.active.has-child
    ul::after {
    display: table;
    clear: both;
    box-shadow: 0 0 0;
    content: "";
  }
  #learn-press-profile
    #profile-nav
    .lp-profile-nav-tabs
    > li.active.has-child
    ul
    li {
    float: left;
    border-right: 1px solid rgba(204, 204, 204, 0.3);
  }
  #learn-press-profile
    #profile-nav
    .lp-profile-nav-tabs
    > li.active.has-child
    ul
    li:last-child {
    border-right: none;
  }
  #learn-press-profile
    #profile-nav
    .lp-profile-nav-tabs
    > li.active.has-child
    ul
    li
    a {
    padding: 0 10px;
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs li:hover ul {
    display: none !important;
  }
  #learn-press-profile
    #profile-nav
    .lp-profile-nav-tabs
    li:not(.has-child)
    > a::after {
    display: none;
  }
}

@media (max-width: 560px) {
  #learn-press-profile #profile-nav .lp-profile-nav-tabs > li {
    max-width: 80px;
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs > li > a {
    font-size: 0;
  }
  #learn-press-profile #profile-nav .lp-profile-nav-tabs > li > a::after {
    margin-left: 0;
  }
}

#learn-press-profile #profile-content {
  float: right;
  width: calc(100% - 300px);
  margin-bottom: 0px;
  padding-top: 58px;
}

@media (max-width: 990px) {
  #learn-press-profile #profile-content {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #learn-press-profile
    #profile-content
    .learn-press-tabs
    .learn-press-tabs__nav {
    display: block;
    width: 100%;
    border: 0;
  }
  #learn-press-profile
    #profile-content
    .learn-press-tabs
    .learn-press-tabs__nav::after {
    display: table;
    clear: both;
    content: "";
  }
  #learn-press-profile
    #profile-content
    .learn-press-tabs
    .learn-press-tabs__nav
    .learn-press-tabs__tab {
    float: left;
    border-right: 0;
  }
  #learn-press-profile
    #profile-content
    .learn-press-tabs
    .learn-press-tabs__nav
    .learn-press-tabs__tab
    label {
    padding-right: 25px;
    padding-left: 25px;
  }
}

#learn-press-profile
  #profile-content
  .lp-archive-courses
  ul.learn-press-courses
  .course {
  margin: 0 0 40px 0;
}

#learn-press-profile
  #profile-content
  .lp-archive-courses
  ul.learn-press-courses
  .course
  .course-item
  .course-content {
  padding: 20px 20px 12px 20px;
}

#learn-press-profile
  #profile-content
  .lp-archive-courses
  ul.learn-press-courses
  .course
  .course-item
  .course-content
  .course-categories,
#learn-press-profile
  #profile-content
  .lp-archive-courses
  ul.learn-press-courses
  .course
  .course-item
  .course-content
  .course-instructor {
  margin-bottom: 14px;
}

#learn-press-profile
  #profile-content
  .lp-archive-courses
  ul.learn-press-courses
  .course
  .course-item
  .course-content
  .course-categories {
  padding: 0;
}

#learn-press-profile #profile-content .lp-button {
  display: block;
  margin: 0 auto 52px auto;
  padding: 13px 34px;
  border-color: var(--lp-secondary-color);
  font-size: 14px;
  font-weight: 500;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

#learn-press-profile #profile-content .lp-button:hover {
  border-color: var(--lp-primary-color);
}

#learn-press-profile
  #profile-content
  #profile-content-orders
  .profile-orders
  .profile-heading {
  display: none;
}

#learn-press-profile
  #profile-content
  #profile-content-orders
  .profile-orders
  table {
  margin-top: 0;
}

#learn-press-profile
  #profile-content
  #profile-content-orders
  .column-order-actions
  a {
  margin-right: 6px;
}

#learn-press-profile #dashboard-general-statistic {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

#learn-press-profile .dashboard-general-statistic__row {
  display: flex;
  margin: 0 -15px;
  justify-content: center;
  flex-wrap: wrap;
}

#learn-press-profile .dashboard-general-statistic__row .statistic-box {
  width: 270px;
  margin: 0 15px 40px 15px;
  padding: 27px 25px 15px 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #f9fafc;
  text-align: center;
}

@media (max-width: 990px) {
  #learn-press-profile .dashboard-general-statistic__row .statistic-box {
    width: 45%;
  }
}

@media (max-width: 767px) {
  #learn-press-profile .dashboard-general-statistic__row .statistic-box {
    width: 100%;
    margin: 0 15px 20px 15px;
  }
}

#learn-press-profile
  .dashboard-general-statistic__row
  .statistic-box
  .statistic-box__text {
  margin: 0 0 7px 0;
  padding: 0;
  color: #666;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}

#learn-press-profile
  .dashboard-general-statistic__row
  .statistic-box
  .statistic-box__number {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
}

#learn-press-profile .dashboard-general-statistic__row .statistic-box:hover {
  background: #eaeaea;
}

#learn-press-profile
  .dashboard-general-statistic__row[data-col="2"]
  .statistic-box {
  width: 50%;
}

#learn-press-profile
  .dashboard-general-statistic__row[data-col="4"]
  .statistic-box {
  width: 25%;
}

#learn-press-profile
  .dashboard-general-statistic__row[data-col="5"]
  .statistic-box {
  width: 20%;
}

#learn-press-profile .profile-courses > h3 {
  margin-top: 0;
  margin-bottom: 36px;
  color: #333;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
}

#learn-press-profile-basic-information .form-field,
form[name="profile-change-password"] .form-field {
  margin: 0 10px 24px 10px;
}

#learn-press-profile-basic-information .form-field > label,
form[name="profile-change-password"] .form-field > label {
  margin: 0 0 12px 0;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}

#learn-press-profile-basic-information .form-field .form-field-input input,
form[name="profile-change-password"] .form-field .form-field-input input {
  height: 43px;
  padding: 8px 20px;
  font-weight: 400;
}

#learn-press-profile-basic-information
  .form-field
  .form-field-input
  input[type="password"],
form[name="profile-change-password"]
  .form-field
  .form-field-input
  input[type="password"] {
  padding: 8px 20px;
}

#learn-press-profile-basic-information .form-field .form-field-input textarea,
form[name="profile-change-password"] .form-field .form-field-input textarea {
  padding: 9px 20px 16px 20px;
  font-weight: 300;
  line-height: 24px;
}

#learn-press-profile-basic-information .form-field .form-field-input input,
#learn-press-profile-basic-information .form-field .form-field-input textarea,
form[name="profile-change-password"] .form-field .form-field-input input,
form[name="profile-change-password"] .form-field .form-field-input textarea {
  border: 1px solid #ccc;
  color: #999;
  font-size: 14px;
  font-style: italic;
}

#learn-press-profile-basic-information
  .form-field
  .form-field-input
  input:focus,
#learn-press-profile-basic-information
  .form-field
  .form-field-input
  textarea:focus,
form[name="profile-change-password"] .form-field .form-field-input input:focus,
form[name="profile-change-password"]
  .form-field
  .form-field-input
  textarea:focus {
  border-color: var(--lp-primary-color);
  outline: 0;
  color: #222;
}

#learn-press-profile-basic-information
  .form-field
  .form-field-input
  ::placeholder,
form[name="profile-change-password"]
  .form-field
  .form-field-input
  ::placeholder {
  opacity: 1;
  color: #999;
}

#learn-press-profile-basic-information
  .form-field
  .form-field-input
  :-ms-input-placeholder,
form[name="profile-change-password"]
  .form-field
  .form-field-input
  :-ms-input-placeholder {
  color: #999;
}

#learn-press-profile-basic-information
  .form-field
  .form-field-input
  ::-ms-input-placeholder,
form[name="profile-change-password"]
  .form-field
  .form-field-input
  ::-ms-input-placeholder {
  color: #999;
}

#learn-press-profile-basic-information
  .form-field
  .form-field-input
  .description,
form[name="profile-change-password"]
  .form-field
  .form-field-input
  .description {
  margin-top: 15px;
}

@media (max-width: 767px) {
  #learn-press-profile-basic-information .form-field,
  form[name="profile-change-password"] .form-field {
    margin: 0 0 24px 0;
  }
}

#learn-press-profile-basic-information .form-field__50,
form[name="profile-change-password"] .form-field__50 {
  float: left;
  width: calc(50% - 20px);
  margin-right: 10px;
}

@media (max-width: 767px) {
  #learn-press-profile-basic-information .form-field__50,
  form[name="profile-change-password"] .form-field__50 {
    float: unset;
    width: 100%;
    margin: 0 0 24px 0;
  }
}

#learn-press-profile-basic-information .form-field__clear,
form[name="profile-change-password"] .form-field__clear {
  clear: both;
}

#learn-press-profile-basic-information button,
form[name="profile-change-password"] button {
  padding: 16px 24px;
  border: 0;
  border-radius: 3px;
  color: #fff;
  background: var(--lp-primary-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

#learn-press-profile-basic-information button:hover,
form[name="profile-change-password"] button:hover {
  opacity: 0.5;
  background: var(--lp-primary-color);
}

#learn-press-profile-basic-information p,
form[name="profile-change-password"] p {
  margin: 0;
}

#learn-press-profile-basic-information .form-fields {
  margin: 0 -10px;
}

#learn-press-profile-basic-information .form-fields::after {
  display: block;
  clear: both;
  content: "";
}

@media (max-width: 767px) {
  #learn-press-profile-basic-information .form-fields {
    margin: 0;
  }
}

#lp-user-edit-avatar {
  position: relative;
  margin-bottom: 50px;
}

#lp-user-edit-avatar img {
  vertical-align: top;
}

#lp-user-edit-avatar .lp-avatar-preview {
  position: relative;
  margin: 0 auto;
}

#lp-user-edit-avatar .lp-avatar-crop-image {
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ddd;
}

#lp-user-edit-avatar .lp-avatar-crop-image .crop-container {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

#lp-user-edit-avatar .lp-avatar-crop-image .crop-container img {
  position: absolute;
  left: 0 !important;
  max-width: none;
  margin-left: 50%;
  line-height: 1;
  vertical-align: middle;
  transform: translateX(-50%);
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  opacity: 0;
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-controls::before {
  display: block;
  width: 100%;
  height: 40px;
  background: rgba(255, 255, 255, 0.53);
  content: "";
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-controls .lp-zoom {
  position: absolute;
  top: 50%;
  right: 100px;
  left: 20px;
  margin-top: -1px;
}

#lp-user-edit-avatar
  .lp-avatar-crop-image
  .lp-crop-controls
  .lp-zoom
  .ui-slider {
  position: absolute;
  width: 100%;
  height: 2px;
  background: #6d6d6d;
  cursor: move;
}

#lp-user-edit-avatar
  .lp-avatar-crop-image
  .lp-crop-controls
  .lp-zoom
  .ui-slider
  .ui-slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -9px;
  margin-left: -10px;
  border-radius: 50%;
  background: #ffb608;
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-controls .lp-save-upload,
#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-controls .lp-cancel-upload {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  opacity: 0.7;
  color: #f02425;
  background: #ffffff59;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

#lp-user-edit-avatar
  .lp-avatar-crop-image
  .lp-crop-controls
  .lp-save-upload:hover,
#lp-user-edit-avatar
  .lp-avatar-crop-image
  .lp-crop-controls
  .lp-cancel-upload:hover {
  opacity: 1;
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-controls .lp-save-upload {
  right: 40px;
  color: #3db748;
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-area {
  position: absolute;
  top: 0;
  left: 50%;
  box-sizing: border-box;
  border: 2px dashed #999;
  transform: translateX(-50%);
  pointer-events: none;
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-area::before,
#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-area::after {
  display: block;
  position: absolute;
  top: -2px;
  bottom: -2px;
  width: 1000px;
  opacity: 0.6;
  background: #000;
  content: "";
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-area::before {
  left: -2px;
  transform: translateX(-100%);
}

#lp-user-edit-avatar .lp-avatar-crop-image .lp-crop-area::after {
  left: 100%;
  margin-left: 2px;
}

#lp-user-edit-avatar:hover .lp-crop-controls {
  opacity: 1;
}

#lp-user-edit-avatar .profile-picture {
  display: none;
  width: 100%;
  margin-right: -100%;
}

#lp-user-edit-avatar .profile-picture img {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

#lp-user-edit-avatar .profile-picture.profile-avatar-current {
  display: block;
}

#lp-user-edit-avatar #lp-avatar-actions {
  display: flex;
  top: 50%;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  justify-content: center;
}

#lp-user-edit-avatar #lp-avatar-actions a {
  display: inline-block;
  z-index: 10;
  height: 40px;
  margin: 0 1px 0 0;
  padding: 0 25px;
  border: 1px solid #eee;
  border-radius: 4px;
  color: #444;
  background: #fff;
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  flex: 0 0 auto;
}

#lp-user-edit-avatar #lp-avatar-actions a i {
  margin-right: 6px;
  font-size: 14px;
}

#lp-user-edit-avatar #lp-avatar-actions a#lp-remove-upload-photo {
  display: none;
  margin-left: 10px;
}

#lp-user-edit-avatar[data-custom="yes"]
  #lp-avatar-actions
  #lp-remove-upload-photo {
  display: block;
}

.recover-order__title {
  margin-bottom: 5px;
}

.recover-order__description {
  margin-bottom: 20px;
  color: #999;
  font-size: 0.8em;
  font-style: italic;
}

div.order-recover {
  display: flex;
}

div.order-recover > .button-recover-order {
  margin: 0;
  margin-left: 20px;
  flex: 0 0 auto;
}

div.order-recover > .button-recover-order.loading::before {
  display: inline-block;
  margin-right: 5px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f110";
  animation: lp-rotating 1s linear infinite;
}

div.order-recover input[type="text"] {
  width: 100%;
  height: 43px;
  margin-right: 10px;
  padding: 0 22px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  line-height: 43px;
  flex: 1 1 auto;
}

.learn-press-profile-course__progress .lp_profile_course_progress {
  border: 1px solid #eee;
}

.learn-press-profile-course__progress .lp_profile_course_progress__item {
  display: grid;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #eee;
  grid-template-columns: 90px 1fr 80px 140px 140px;
  column-gap: 20px;
}

.learn-press-profile-course__progress .lp_profile_course_progress__header {
  background-color: #eee;
}

.lp_profile_course_progress__nav button.loading::before {
  display: inline-block;
  margin-right: 5px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f110";
  -webkit-animation: lp-rotating 1s linear infinite;
  -moz-animation: lp-rotating 1s linear infinite;
  animation: lp-rotating 1s linear infinite;
}

.learn-press-profile-course__tab__inner {
  display: flex;
  margin: 40px 0 0 0;
  padding: 0;
  background: #eee;
  list-style: none !important;
}

.learn-press-profile-course__tab__inner > li {
  list-style: none;
}

.learn-press-profile-course__tab__inner a {
  display: inline-block;
  padding: 15px 30px;
  font-weight: 600;
}

.learn-press-profile-course__tab__inner a.active {
  border-top: 4px solid var(--lp-primary-color);
  color: var(--lp-primary-color);
  background: #fff;
}

.learn-press-course-tab-filters .learn-press-filters {
  margin-top: 20px;
  margin-left: 0;
  padding: 0;
  list-style: none;
}

.learn-press-course-tab-filters .learn-press-filters a.active {
  color: var(--lp-primary-color);
}

#learn-press-course-tabs .course-tab-panels .course-tab-panel {
  display: none;
  padding-top: 56px;
}

#learn-press-course-tabs .course-tab-panels .course-tab-panel.active {
  display: block;
}

#learn-press-course-tabs
  .course-tab-panels
  .course-tab-panel.course-tab-panel-instructor,
#learn-press-course-tabs
  .course-tab-panels
  .course-tab-panel.course-tab-panel-faqs {
  padding-top: 60px;
}

#learn-press-course-tabs
  .course-tab-panels
  .course-tab-panel.course-tab-panel-reviews {
  padding: 56px 0 0 0;
}

#learn-press-course-tabs
  .course-tab-panels
  .course-tab-panel
  .course-description
  h4 {
  margin-top: 0;
  margin-bottom: 1.125rem;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

#learn-press-course-tabs
  .course-tab-panels
  .course-tab-panel
  .course-description
  p {
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

#learn-press-course-tabs
  .course-tab-panels
  .course-tab-panel
  .course-description
  img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

#learn-press-course-tabs.show-all .course-tab-panel {
  margin-bottom: 40px;
}

#learn-press-course .lp-course-author {
  display: flex;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  #learn-press-course .lp-course-author {
    flex-direction: column;
    padding: 0 15px;
  }
}

#learn-press-course .lp-course-author .course-author__pull-left {
  margin-right: 30px;
  text-align: center;
  align-items: center;
}

@media (max-width: 767px) {
  #learn-press-course .lp-course-author .course-author__pull-left {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

#learn-press-course .lp-course-author .course-author__pull-left img {
  width: 100%;
  max-width: 96px;
  margin-bottom: 20px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

#learn-press-course .lp-course-author .course-author__pull-right {
  flex: 1;
}

@media (max-width: 767px) {
  #learn-press-course .lp-course-author .course-author__pull-right {
    width: 100%;
    text-align: center;
  }
}

#learn-press-course .lp-course-author .course-author__pull-right .author-title {
  margin-bottom: 18px;
}

#learn-press-course
  .lp-course-author
  .course-author__pull-right
  .author-title
  a {
  color: #333;
  box-shadow: none;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

#learn-press-course
  .lp-course-author
  .course-author__pull-right
  .author-title
  a:hover {
  color: var(--lp-primary-color);
}

#learn-press-course
  .lp-course-author
  .course-author__pull-right
  .author-description {
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

#learn-press-course .lp-course-author .author-socials {
  display: grid;
  grid-template-columns: repeat(4, 33px);
  gap: 10px;
  justify-content: center;
}

#learn-press-course .lp-course-author .author-socials > a {
  display: inline-block;
  width: 33px;
  height: 33px;
  margin: 0;
  border: 1px solid #ededed;
  border-radius: 50%;
  color: #878787;
  box-shadow: none;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
  vertical-align: middle;
  transition: all 0.3s;
}

#learn-press-course .lp-course-author .author-socials > a:hover {
  border-color: var(--lp-primary-color);
  color: #fff;
  background: var(--lp-primary-color);
}

#learn-press-course .lp-course-author .author-socials .fa-googleplus::before {
  content: "\f0d5";
}

#learn-press-course div.lp-list-co-instructor {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

#learn-press-course div.lp-list-co-instructor__item {
  display: flex;
  column-gap: 30px;
}

@media (max-width: 767px) {
  #learn-press-course div.lp-list-co-instructor__item {
    align-items: center;
    flex-direction: column;
    padding: 0 15px;
    column-gap: 0;
    row-gap: 10px;
  }
}

#learn-press-course div.lp-list-co-instructor__avatar > img {
  width: 100%;
  max-width: 96px;
  border-radius: 999px;
}

#learn-press-course div.lp-list-co-instructor__bio {
  flex: 1;
}

#learn-press-course div.lp-list-co-instructor__bio__top > a {
  font-size: 18px;
  font-weight: 600;
}

#learn-press-course div.lp-list-co-instructor__bio__description {
  font-size: 1rem;
}

#learn-press-course .course-price {
  margin-bottom: 10px;
}

#learn-press-course .course-price .origin-price,
#learn-press-course .course-price .price {
  vertical-align: middle;
}

#learn-press-course .course-price .origin-price {
  margin-right: 10px;
  font-size: 18px;
  font-style: italic;
  text-decoration: line-through;
}

#learn-press-course .course-price .price {
  font-size: 24px;
}

#learn-press-course .lp-course-buttons {
  margin-bottom: 20px;
}

#learn-press-course .course-featured-review .featured-review__title {
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
}

#learn-press-course .course-featured-review .featured-review__stars {
  padding-bottom: 12px;
  color: var(--lp-primary-color);
  font-size: 16px;
}

#learn-press-course .course-featured-review .featured-review__content {
  position: relative;
  color: #666;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  line-height: 24px;
}

#learn-press-course .course-featured-review .featured-review__content::after {
  position: absolute;
  top: -42px;
  right: -15px;
  color: rgba(102, 102, 102, 0.1);
  font-family: Arial;
  font-size: 180px;
  font-weight: 700;
  font-style: normal;
  content: "‘‘";
  transform: rotate(180deg);
}

#learn-press-course .course-tags a {
  display: inline-block;
  padding: 3px 5px;
  border-radius: 4px;
  color: #fff;
  background: #9aa5ab;
  font-size: 12px;
  line-height: 1;
}

.edit-content {
  margin-left: 5px;
}

ul.learn-press-nav-tabs {
  display: flex;
  max-height: 60px;
  margin: 0;
  padding: 0;
  border: 1px solid rgba(204, 204, 204, 0.3);
  border-bottom: 0;
  background: #f1f2f8;
  list-style: none;
}

ul.learn-press-nav-tabs .course-nav {
  float: left;
  position: relative;
  margin: 0;
  border-right: 1px solid rgba(204, 204, 204, 0.3);
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  list-style: none;
}

ul.learn-press-nav-tabs .course-nav:first-child.active::after {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 1px;
  height: 100%;
  background: #fff;
  content: "";
}

ul.learn-press-nav-tabs .course-nav:last-child {
  border-right: 0;
}

ul.learn-press-nav-tabs .course-nav:last-child.active::after {
  position: absolute;
  top: -1px;
  right: -2px;
  width: 2px;
  height: 100%;
  background: #fff;
  content: "";
}

ul.learn-press-nav-tabs .course-nav.active {
  border-bottom: 0;
}

ul.learn-press-nav-tabs .course-nav.active::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--lp-primary-color);
  content: "";
}

ul.learn-press-nav-tabs .course-nav.active label {
  color: var(--lp-primary-color);
}

ul.learn-press-nav-tabs .course-nav a {
  display: inline-block;
  padding: 10px 20px;
  border-bottom: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

ul.learn-press-nav-tabs .course-nav a:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

ul.learn-press-nav-tabs[data-tabs] .course-nav {
  flex: 1;
}

ul.learn-press-nav-tabs[data-tabs] .course-nav a {
  width: 100%;
  text-align: center;
}

ul.learn-press-nav-tabs::after {
  display: block;
  clear: both;
  content: "";
}

@media (max-width: 767px) {
  ul.learn-press-nav-tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-height: none;
  }
}

.course-item-popup #tab-curriculum {
  display: block;
}

#learn-press-course-curriculum.course-curriculum ul.curriculum-sections {
  position: relative;
  z-index: 9999;
  margin: 0;
  padding: 0;
  background: #fff;
  list-style: none;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section {
  margin: 0;
  padding: 0 0 42px 0;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section:last-child {
  padding: 0;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section.section-empty
  .section-header {
  margin-bottom: 20px;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section.section-empty
  .learn-press-message {
  margin-right: 15px;
  margin-left: 15px;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header {
  display: table;
  width: 100%;
  padding: 0 0 26px 0;
  border-bottom: 0;
  border-bottom: 1px solid #d9e0f1;
  background: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-left {
  vertical-align: top;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-left
  .section-title {
  padding: 0;
  color: #666;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: capitalize;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-title,
#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-desc {
  margin: 0 0 10px 0;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-title {
  position: relative;
  margin-bottom: 0;
  padding: 8px 0;
  color: #5383f7;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-title
  span.show-desc {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-title
  span.show-desc::before {
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 900;
  content: "\f107";
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-title
  span.show-desc:hover::before {
  border-top-color: #ccc;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-desc {
  margin: 0;
  font-size: 14px;
  font-style: italic;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-header
  .section-meta {
  display: block;
  padding-top: 17px;
  padding-bottom: 15px;
  font-size: 14px;
  text-align: right;
  vertical-align: middle;
  white-space: nowrap;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content {
  margin: 0 0 15px 0;
  padding: 0;
  list-style: none;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item {
  display: flex;
  position: relative;
  margin: 0 0 2px 0;
  padding: 0 15px 0 30px;
  background: rgba(241, 242, 248, 0.4);
  font-size: 14px;
  transition: padding-left linear 0.15s;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  > span {
  display: flex;
  width: 28px;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  align-items: center;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .section-item-link {
  display: table;
  width: calc(100% - 28px);
  border-bottom: none;
  outline: none;
  box-shadow: none;
  line-height: 1.5;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .section-item-link:hover
  .item-name {
  color: var(--lp-primary-color);
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .section-item-link::before {
  display: table-cell;
  display: table-cell;
  left: 0;
  width: 20px;
  padding: 15px 0;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 400;
  vertical-align: middle;
  transition: left linear 0.15s;
}

@media (max-width: 767px) {
  #learn-press-course-curriculum.course-curriculum
    ul.curriculum-sections
    .section-content
    .course-item
    .section-item-link
    .course-item-meta {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  #learn-press-course-curriculum.course-curriculum
    ul.curriculum-sections
    .section-content
    .course-item
    .section-item-link
    .course-item-meta
    .duration {
    margin-bottom: 5px;
  }
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .item-icon,
#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .item-name {
  display: table-cell;
  padding: 17px 16px;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .item-icon::before {
  font-size: 18px;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .item-icon.icon-lock {
  float: right;
  margin-top: 15px;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .course-item-meta {
  display: table-cell;
  padding: 10px 0;
  text-align: right;
  vertical-align: middle;
  white-space: nowrap;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .course-item-meta
  .item-meta {
  display: inline-block;
  height: 23px;
  margin-left: 14px;
  padding: 0 8px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  vertical-align: middle;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .course-item-meta
  .item-meta.final-quiz {
  background: #14c4ff;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .course-item-meta
  .item-meta.trans {
  padding: 0;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .course-item-meta
  .count-questions {
  background: #9672cf;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .course-item-meta
  .duration {
  background: #c0c0c0;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .course-item-meta
  .course-item-status {
  padding: 0;
  color: #999;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item
  .course-item-meta
  .course-item-status::before {
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 900;
  vertical-align: middle;
  text-transform: lowercase;
  content: "\f00c";
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.course-item-lp_quiz
  .section-item-link::before {
  content: "\f059";
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.course-item-lp_lesson
  .section-item-link::before {
  content: "\f15b";
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.course-item-lp_lesson.course-item-type-video
  .section-item-link::before {
  content: "\f03d";
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.course-item-lp_lesson.course-item-type-audio
  .section-item-link::before {
  content: "\f028";
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.item-preview
  .course-item-preview {
  background: #00adff;
  font-style: normal;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.item-preview
  .course-item-preview::before {
  color: #fff;
  vertical-align: baseline;
  content: attr(data-preview);
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.item-locked
  .course-item-status::before {
  color: var(--lp-secondary-color);
  content: "\f023";
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.has-status {
  padding-top: 1px;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.has-status.status-completed
  .course-item-status::before,
#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.has-status.status-evaluated
  .course-item-status::before {
  color: #3bb54a;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.has-status.item-failed
  .course-item-status::before,
#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.has-status.failed
  .course-item-status::before {
  border-color: #f02425;
  color: #f02425;
  content: "\f00d";
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 3px;
  height: 0;
  background: #00adff;
  content: "";
  transition: height linear 0.15s, top linear 0.15s;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.current {
  padding-left: 10px;
  background: #f9f9f9;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.current
  a::before {
  left: 10px;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-content
  .course-item.current::before {
  top: 0;
  height: 100%;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-item-link {
  color: #777;
  text-decoration: none;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-desc {
  color: #999;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-title.c
  + .section-desc {
  display: block;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .section-title.c
  span.show-desc::before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  top: 0;
}

#learn-press-course-curriculum.course-curriculum
  ul.curriculum-sections
  .item-meta.duration {
  background: #d9e0f1;
}

body.course-item-popupx {
  overflow: hidden;
  _opacity: 0;
}

body.course-item-popupx #learn-press-course-curriculum {
  overflow: auto;
  position: fixed;
  z-index: 9999;
  top: 32px;
  bottom: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid #ddd;
  background: #fff;
}

body.course-item-popupx #learn-press-course-curriculum .section-header {
  padding: 0 15px;
}

body.course-item-popupx
  #learn-press-course-curriculum
  .section-header
  .section-desc {
  margin: -10px 0 5px;
}

body.course-item-popupx #learn-press-course-curriculum .course-item {
  padding-right: 15px;
  padding-left: 15px;
}

body.course-item-popupx #learn-press-course-curriculum .course-item a::before {
  left: 15px;
}

body.course-item-popupx #learn-press-content-item {
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  top: 32px;
  right: 0;
  bottom: 0;
  left: 400px;
  background: #fff;
}

body.course-item-popupx #learn-press-content-item .content-item-wrap {
  max-width: 900px;
  margin: 10px auto;
}

body.course-item-popupx #learn-press-content-item .course-item-title {
  font-size: 1.4rem;
}

body.course-item-popupx
  #learn-press-content-item
  .content-question-summary
  .review-heading {
  text-align: center;
}

body.course-item-popupx
  #learn-press-content-item
  .content-question-summary
  .question-title {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

body.course-item-popupx #content-item-nav {
  position: fixed;
  z-index: 99999;
  right: 0;
  bottom: 0;
  left: 400px;
  height: 60px;
  border-top: 1px solid #ddd;
  background: #f5f5f5;
}

body.course-item-popupx #content-item-nav .content-item-nav-wrap {
  max-width: 900px;
  margin: 10px auto;
}

body.course-item-popupx #content-item-nav button {
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
}

body.course-item-popupx .comment-form-textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body.course-item-popupx.wpadminbar #learn-press-course-curriculum,
body.course-item-popupx.wpadminbar #learn-press-content-item {
  top: 92px;
}

body.course-item-popupx.wpadminbar #course-item-content-header {
  top: 32px;
}

body.course-item-popupx.wpadminbar .content-item-description {
  margin-bottom: 20px;
}

body.course-item-popupx .content-item-summary {
  margin-bottom: 50px;
}

body.course-item-popupx .content-item-summary > h3 {
  margin-bottom: 20px;
}

body.course-item-popupx .content-item-summary.content-item-video .entry-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #000;
  line-height: 1;
}

body.course-item-popupx
  .content-item-summary.content-item-video
  .entry-video
  iframe {
  width: 100%;
  max-width: 900px;
  margin-bottom: 0;
  vertical-align: top;
}

body.course-item-popupx .learn-press-content-protected-message {
  margin-bottom: 50px;
  padding: 20px;
  background: #ffe0e0;
}

body.course-item-popupx.content-only #learn-press-content-item {
  z-index: 9999999;
  top: 0;
  bottom: 0;
  left: 0;
}

body.course-item-popupx.content-only
  #learn-press-content-item
  #course-item-content-header,
body.course-item-popupx.content-only
  #learn-press-content-item
  #course-item-content-footer {
  display: none;
}

body.course-item-popupx.content-only
  #learn-press-content-item
  .content-item-scrollable {
  bottom: 0;
}

body #ifr-course-item {
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

body.full-screen-content-item
  #course-item-content-header
  .toggle-content-item::before {
  font: 28px/60px "Font Awesome 5 Free";
  content: "\f066";
}

body .content-item-summary .form-button-finish-course,
body .lp-quiz-buttons .form-button-finish-course {
  float: right;
}

#wpadminbar #wp-admin-bar-edit-lp_quiz .ab-item::before,
#wpadminbar #wp-admin-bar-edit-lp_lesson .ab-item::before,
#wpadminbar #wp-admin-bar-edit-lp_question .ab-item::before {
  top: 2px;
  font-family: "Font Awesome 5 Free";
}

#wpadminbar #wp-admin-bar-edit-lp_quiz .ab-item::before {
  content: "\f017";
}

#wpadminbar #wp-admin-bar-edit-lp_lesson .ab-item::before {
  content: "\f15c";
}

#wpadminbar #wp-admin-bar-edit-lp_question .ab-item::before {
  content: "\f29c";
}

.scroll-wrapper {
  overflow: hidden;
  opacity: 0;
}

.scroll-wrapper .scroll-element {
  background: transparent;
}

.scroll-wrapper .scroll-element.scroll-y.scroll-scrolly_visible {
  transition: opacity 0.25s;
}

.scroll-wrapper:hover .scroll-element.scroll-y.scroll-scrolly_visible {
  opacity: 0.7;
}

.course-remaining-time .lp-label.label-enrolled {
  font-size: inherit;
}

.learn-press-course-results-progress {
  margin-right: -4%;
}

.learn-press-course-results-progress .items-progress,
.learn-press-course-results-progress .course-progress {
  float: left;
  width: 46%;
  margin-right: 4%;
  margin-bottom: 30px;
}

.learn-press-course-results-progress .items-progress .items-progress__heading,
.learn-press-course-results-progress .course-progress .items-progress__heading {
  margin-bottom: 10px;
}

.learn-press-course-results-progress .items-progress .lp-progress-bar,
.learn-press-course-results-progress .course-progress .lp-progress-bar {
  overflow: hidden;
  position: relative;
  height: 10px;
  border-radius: 5px;
}

.learn-press-course-results-progress
  .items-progress
  .lp-progress-bar
  .lp-progress-value,
.learn-press-course-results-progress
  .course-progress
  .lp-progress-bar
  .lp-progress-value {
  position: absolute;
  width: 100%;
  height: 10px;
  margin-left: -100%;
  border-radius: 5px;
}

.learn-press-course-results-progress
  .items-progress
  .lp-course-status
  .grade.failed,
.learn-press-course-results-progress
  .course-progress
  .lp-course-status
  .grade.failed {
  background: #f00;
}

.learn-press-course-results-progress
  .items-progress
  .lp-course-status
  .grade.passed,
.learn-press-course-results-progress
  .course-progress
  .lp-course-status
  .grade.passed {
  background: #3eadff;
}

.learn-press-course-results-progress::after {
  display: block;
  clear: both;
  content: "";
}

#course-item-content-header,
#course-item-content-footer {
  position: fixed;
  right: 0;
  height: 60px;
  line-height: 60px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#course-item-content-header {
  z-index: 100;
  top: 0;
  left: 0;
  padding: 0;
  border-bottom: 1px solid #ddd;
  background: #e7f7ff;
}

#course-item-content-header .course-item-search {
  float: left;
  position: relative;
  box-sizing: border-box;
  width: 400px;
  padding: 15px;
}

#course-item-content-header .course-item-search input {
  float: left;
  width: 385px;
  height: 30px;
  padding: 0;
  border: none;
  color: #aaa;
  background: transparent;
  box-shadow: none;
  font-style: italic;
}

#course-item-content-header .course-item-search input:focus {
  outline: none;
  color: #777;
  box-shadow: none;
}

#course-item-content-header .course-item-search button {
  position: absolute;
  z-index: 99;
  right: 15px;
  height: 30px;
  margin-left: -30px;
  padding: 0;
  border: none;
  color: #777;
  background: transparent;
  font-size: 15px;
  line-height: 30px;
}

#course-item-content-header .course-item-search button::after {
  font-family: "Font Awesome 5 Free";
  content: "\f002";
}

#course-item-content-header .course-item-search.has-keyword button::after {
  content: "\f00d";
}

#course-item-content-header .course-title {
  float: left;
  clear: none;
  height: 60px;
  margin: 0;
  padding: 0 15px;
  font-size: 1.4rem;
  line-height: 60px;
}

#course-item-content-header .course-title a {
  box-shadow: none;
}

#course-item-content-header .course-title::before {
  display: none;
  content: "";
}

#course-item-content-header .form-button.lp-button-back {
  float: right;
  margin-right: 15px;
}

#course-item-content-header .toggle-content-item {
  display: inline-block;
  float: right;
  width: 60px;
  height: 60px;
  border-left: 1px solid #ddd;
  text-align: center;
}

#course-item-content-header .toggle-content-item::before {
  font: 28px/60px "Font Awesome 5 Free";
  content: "\f065";
}

#course-item-content-footer {
  display: none;
  bottom: 0;
  left: 400px;
  padding: 15px;
  border-top: 1px solid #ddd;
}

#course-item-content-footer button,
#course-item-content-footer .lp-button {
  height: 35px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 30px;
}

.learn-press-form.completed button::before {
  margin-right: 10px;
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  content: "\f00c";
}

.lp-course-progress {
  position: relative;
}

.lp-course-progress .lp-passing-conditional {
  position: absolute;
  top: 0;
  width: 3px;
  height: 6px;
  margin-left: -1px;
  background: var(--lp-secondary-color);
}

.viewing-course-item
  #learn-press-course-curriculum
  .section-header
  .section-desc {
  display: none;
}

/***********/
.course-meta {
  display: flex;
  margin-bottom: 40px;
}

.course-meta .course-meta__pull-left,
.course-meta .course-meta__pull-right {
  display: flex;
}

.course-meta .course-meta__pull-left {
  flex: 1;
}

.course-meta .course-meta__pull-left .meta-item {
  margin-right: 10px;
}

.course-meta .course-meta__pull-right {
  flex: 0 0 50%;
}

.course-meta .course-meta__pull-right .meta-item {
  margin-left: 10px;
}

.course-meta.course-meta-primary .meta-item {
  flex: 1;
}

.course-meta.two-columns .course-meta {
  flex: 1;
}

.course-extra-box {
  margin-bottom: 3px;
  border: 1px solid rgba(204, 204, 204, 0.3);
  border-radius: 5px;
}

.course-extra-box__title {
  --extra-height: 54px;
  display: flex;
  align-items: center;
  position: relative;
  height: var(--extra-height);
  margin: 0;
  padding: 0 45px 0 38px;
  background: rgba(181, 187, 211, 0.15);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
}

.course-extra-box__title::after {
  position: absolute;
  top: 0;
  right: 20px;
  color: #666;
  font-family: "Font Awesome 5 Free";
  line-height: var(--extra-height);
  content: "\f0d7";
}

.course-extra-box__content {
  display: none;
}

.course-extra-box__content-inner {
  -webkit-animation-name: course-extra-box__content-inner-transform;
  animation-name: course-extra-box__content-inner-transform;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

.course-extra-box__content ul,
.course-extra-box__content li {
  list-style: none;
}

.course-extra-box__content ul {
  margin: 0;
  padding: 0;
}

.course-extra-box__content li {
  margin: 0;
  padding: 22px 38px 14px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  color: #666;
  font-size: 16px;
  font-weight: 300;
}

.course-extra-box__content li::before {
  margin-right: 8px;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
}

.course-extra-box__content li:last-child {
  border-bottom: 0;
}

.course-extra-box:last-child {
  margin-bottom: 60px;
}

.course-extra-box.active .course-extra-box__title::after {
  content: "\f0d8";
}

input[name="course-extra-box-ratio"] {
  display: none;
}

input[name="course-extra-box-ratio"]:checked
  + .course-extra-box
  .course-extra-box__content {
  display: block;
}

input[name="course-extra-box-ratio"]:checked
  + .course-extra-box
  .course-extra-box__content
  .course-extra-box__content-inner {
  transform: scale(1);
}

@-webkit-keyframes course-extra-box__content-inner-transform {
  from {
    opacity: 0;
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes course-extra-box__content-inner-transform {
  from {
    opacity: 0;
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.course-tab-panel-faqs {
  padding-top: 64px;
}

@media (max-width: 767px) {
  .course-tab-panel-faqs {
    padding: 0 15px;
  }
}

.course-tab-panel-faqs .course-faqs-box {
  margin-bottom: 20px;
  border: 1px solid rgba(204, 204, 204, 0.6);
  border-radius: 5px;
}

.course-tab-panel-faqs .course-faqs-box__title {
  display: block;
  position: relative;
  margin: 0;
  padding: 25px 45px 25px 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
  cursor: pointer;
}

.course-tab-panel-faqs .course-faqs-box__title::after {
  position: absolute;
  top: 50%;
  right: 28px;
  color: #6c6c6c;
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 900;
  content: "\f078";
  transform: translateY(-50%);
}

.course-tab-panel-faqs .course-faqs-box .course-faqs-box__content {
  display: none;
}

.course-tab-panel-faqs
  .course-faqs-box
  .course-faqs-box__content
  .course-faqs-box__content-inner {
  padding: 0 28px 33px 28px;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  -webkit-animation-name: course-faqs-box__content-inner-transform;
  animation-name: course-faqs-box__content-inner-transform;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

.course-tab-panel-faqs .course-faqs-box:last-child {
  margin-bottom: 40px;
}

.course-tab-panel-faqs .course-faqs-box:hover {
  background: rgba(241, 242, 248, 0.4);
}

.course-tab-panel-faqs .course-faqs-box:hover .course-faqs-box__title {
  color: var(--lp-primary-color);
}

input[name="course-faqs-box-ratio"] {
  display: none;
}

input[name="course-faqs-box-ratio"]:checked
  + .course-faqs-box
  .course-faqs-box__content {
  display: block;
}

input[name="course-faqs-box-ratio"]:checked + .course-faqs-box {
  background: rgba(241, 242, 248, 0.4);
}

input[name="course-faqs-box-ratio"]:checked
  + .course-faqs-box
  .course-faqs-box__title {
  color: var(--lp-primary-color);
}

input[name="course-faqs-box-ratio"]:checked
  + .course-faqs-box
  .course-faqs-box__title::after {
  content: "\f077";
}

@-webkit-keyframes course-faqs-box__content-inner-transform {
  from {
    opacity: 0;
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes course-faqs-box__content-inner-transform {
  from {
    opacity: 0;
    -webkit-transform: translateX(5%);
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

/***********/
@media screen and (max-width: 1300px) {
  body.course-item-popup #course-item-content-header .course-item-search {
    width: 300px;
  }
  body.course-item-popup #course-item-content-header .course-item-search input {
    width: 285px;
  }
  body.course-item-popup
    #course-item-content-header
    .course-item-search
    button {
    left: 300px;
  }
  body.course-item-popup #learn-press-course-curriculum {
    width: 300px;
  }
  body.course-item-popup #learn-press-course-curriculum .progress-bg {
    width: 40px;
  }
  body.course-item-popup #content-item-nav,
  body.course-item-popup #learn-press-content-item,
  body.course-item-popup #course-item-content-footer {
    left: 300px;
  }
  body.course-item-popup .section-desc {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  body.course-item-popupx #course-item-content-header .course-item-search {
    width: 300px;
  }
  body.course-item-popupx
    #course-item-content-header
    .course-item-search
    input {
    width: 285px;
  }
  body.course-item-popupx
    #course-item-content-header
    .course-item-search
    button {
    left: 300px;
  }
  body.course-item-popupx #learn-press-course-curriculum {
    width: 300px;
  }
  body.course-item-popupx #content-item-nav,
  body.course-item-popupx #learn-press-content-item,
  body.course-item-popupx #course-item-content-footer {
    left: 300px;
  }
  body.course-item-popupx #learn-press-content-item .content-item-nav-wrap,
  body.course-item-popupx #learn-press-content-item .content-item-wrap {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  body.course-item-popup #course-item-content-header .course-item-search {
    width: 200px;
  }
  body.course-item-popup #course-item-content-header .course-item-search input {
    width: 185px;
  }
  body.course-item-popup
    #course-item-content-header
    .course-item-search
    button {
    left: 200px;
  }
  body.course-item-popup #learn-press-course-curriculum {
    width: 200px;
  }
  body.course-item-popup #content-item-nav,
  body.course-item-popup #learn-press-content-item,
  body.course-item-popup #course-item-content-footer {
    left: 200px;
  }
  body.course-item-popup #learn-press-content-item .content-item-nav-wrap,
  body.course-item-popup #learn-press-content-item .content-item-wrap {
    width: 90%;
  }
  body.course-item-popup.wpadminbar #learn-press-content-item,
  body.course-item-popup.wpadminbar #learn-press-course-curriculum {
    top: 106px;
  }
  body.course-item-popup.wpadminbar #course-item-content-header {
    top: 46px;
  }
  .learn-press-course-results-progress {
    margin-right: 0%;
  }
  .learn-press-course-results-progress .items-progress,
  .learn-press-course-results-progress .course-progress {
    float: none;
    width: 100%;
    margin-right: 0%;
    margin-bottom: 20px;
  }
}

.lp-quiz-buttons {
  margin-bottom: 20px;
  display: block;
  clear: both;
  content: "";
}

.quiz-progress {
  margin-bottom: 30px;
  background: #e7f7ff;
}

.quiz-progress .progress-items {
  display: flex;
}

.quiz-progress .progress-items .progress-item {
  position: relative;
  color: #777;
  font-size: 15px;
  flex: 1;
}

.quiz-progress .progress-items .progress-item .progress-number,
.quiz-progress .progress-items .progress-item .progress-label {
  display: block;
  line-height: 1;
  text-align: center;
}

.quiz-progress .progress-items .progress-item .progress-number {
  margin: 15px 0 10px 0;
  font-size: 20px;
}

.quiz-progress .progress-items .progress-item .progress-label {
  margin-bottom: 15px;
  font-size: 14px;
}

.quiz-progress .progress-items .progress-item i {
  display: none;
  float: left;
  width: 60px;
  height: 60px;
  color: #fff;
  background: #00adff;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
}

.quiz-progress .progress-items .progress-item::after {
  display: block;
  clear: both;
  content: "";
}

.answer-options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.answer-options .answer-option {
  display: flex;
  overflow: hidden;
  position: relative;
  margin: 0 0 18px 0;
  padding: 10px;
  color: #777;
  background: #f5f5f5;
  font-size: 20px;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: background linear 0.25s;
  -moz-transition: background linear 0.25s;
  -ms-transition: background linear 0.25s;
  -o-transition: background linear 0.25s;
  transition: background linear 0.25s;
}

.answer-options .answer-option .option-title {
  display: table-cell;
  font-size: smaller;
}

.answer-options .answer-option .option-title .option-title-content {
  display: inline-block;
  vertical-align: middle;
}

.answer-options .answer-option .option-title::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
  background: #ddd;
  content: "";
  -webkit-transition: background linear 0.25s;
  -moz-transition: background linear 0.25s;
  -ms-transition: background linear 0.25s;
  -o-transition: background linear 0.25s;
  transition: background linear 0.25s;
}

.answer-options .answer-option input[type="checkbox"],
.answer-options .answer-option input[type="radio"] {
  -webkit-appearance: initial;
  -moz-appearance: initial;
  position: relative;
  z-index: 10;
  width: 35px;
  min-width: 35px;
  height: 35px;
  margin: 0 10px 0 3px;
  border: 1px solid #cfcfcf;
  background: #f9fafc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.answer-options .answer-option input[type="checkbox"]:focus,
.answer-options .answer-option input[type="radio"]:focus {
  outline: none;
}

.answer-options .answer-option input[type="checkbox"]::after,
.answer-options .answer-option input[type="radio"]::after {
  position: absolute;
  top: 15px;
  left: 9px;
  box-sizing: content-box;
  margin-top: -10px;
  opacity: 0;
  color: #3db748;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
}

.answer-options
  .answer-option
  input[type="checkbox"]:checked
  ~ .option-title
  .option-title-content,
.answer-options
  .answer-option
  input[type="radio"]:checked
  ~ .option-title
  .option-title-content {
  position: relative;
}

.answer-options
  .answer-option
  input[type="checkbox"]:checked
  ~ .option-title::before,
.answer-options
  .answer-option
  input[type="radio"]:checked
  ~ .option-title::before {
  background: #00adff;
}

.answer-options .answer-option input[type="checkbox"]:checked::after,
.answer-options .answer-option input[type="radio"]:checked::after {
  opacity: 1;
}

.answer-options .answer-option input[type="radio"] {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.answer-options .answer-option input[type="radio"]::before {
  border-radius: 50%;
}

.answer-options .answer-option .option-title {
  margin: 0;
}

.answer-options .answer-option:hover {
  background: #e1f5ff;
}

.answer-options .answer-option.answer-correct {
  background: #e1f5ff;
}

.answer-options
  .answer-option.answer-correct
  input[type="radio"]:checked
  ~ .option-title::before,
.answer-options
  .answer-option.answer-correct
  input[type="checkbox"]:checked
  ~ .option-title::before {
  background: #e1f5ff;
}

.answer-options
  .answer-option.answered-correct
  input[type="radio"]:checked
  ~ .option-title::before,
.answer-options
  .answer-option.answered-correct
  input[type="checkbox"]:checked
  ~ .option-title::before {
  background: #00adff;
}

.answer-options .answer-option.answered-wrong input[type="radio"]::before,
.answer-options .answer-option.answered-wrong input[type="radio"]::after,
.answer-options .answer-option.answered-wrong input[type="checkbox"]::before,
.answer-options .answer-option.answered-wrong input[type="checkbox"]::after {
  border-color: #f00;
}

.answer-options
  .answer-option.answered-wrong
  input[type="radio"]:checked
  ~ .option-title::before,
.answer-options
  .answer-option.answered-wrong
  input[type="checkbox"]:checked
  ~ .option-title::before {
  background: #f00;
}

button[data-counter] {
  position: relative;
}

button[data-counter]::after {
  padding-left: 5px;
  content: "(+" attr(data-counter) ")";
}

.quiz-result {
  max-width: 320px;
  margin: 20px auto 48px;
  text-align: center;
}

.quiz-result .result-heading {
  display: none;
}

.quiz-result.passed .result-message {
  background: #3bb54a;
}

.quiz-result.passed .result-message::after {
  content: "\f00c";
}

.quiz-result .result-message {
  margin-bottom: 30px;
  padding: 10px 0;
  color: #fff;
  background: #f02425;
  font-size: 16px;
  font-weight: 400;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.quiz-result .result-message::after {
  margin-left: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00d";
}

.quiz-result .result-grade .result-achieved,
.quiz-result .result-grade .result-require {
  display: inline-block;
  margin: 0 auto;
}

.quiz-result .result-grade .result-achieved {
  padding-bottom: 7px;
  border-bottom: 1px solid #999;
  color: #333;
  font-size: 28px;
  font-weight: 500;
  line-height: 1;
}

.quiz-result .result-grade .result-require {
  display: block;
  padding-top: 5px;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.quiz-result .result-grade .result-message {
  font-size: 14px;
}

.quiz-result.failed {
  /*.result-achieved {
            color: #c55757;
        }
        .result-message {
            strong {
                color: #c55757;
            }
        }*/
}

.quiz-result.passed .result-achieved {
  color: #04adff;
}

.quiz-result.passed .result-message strong {
  color: #04adff;
}

.quiz-result .result-statistic {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
}

.quiz-result .result-statistic .result-statistic-field {
  display: flex;
  margin: 0;
}

.quiz-result .result-statistic .result-statistic-field span,
.quiz-result .result-statistic .result-statistic-field p {
  margin: 0;
  flex: 1;
}

.quiz-result .result-statistic .result-statistic-field span {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
}

.quiz-result .result-statistic .result-statistic-field span::before {
  display: inline-block;
  width: 15px;
  margin-right: 10px;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 900;
}

.quiz-result .result-statistic .result-statistic-field p {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
  text-align: right;
}

.quiz-result
  .result-statistic
  .result-statistic-field.result-time-spend
  label::before {
  font-weight: 400;
  content: "\f017";
}

.quiz-result
  .result-statistic
  .result-statistic-field.result-point
  label::before {
  font-weight: 400;
  content: "\f005";
}

.quiz-result
  .result-statistic
  .result-statistic-field.result-questions
  label::before {
  font-weight: 400;
  content: "\f059";
}

.quiz-result
  .result-statistic
  .result-statistic-field.result-questions-correct
  label::before {
  color: #3db748;
  content: "\f00c";
}

.quiz-result
  .result-statistic
  .result-statistic-field.result-questions-wrong
  label::before {
  color: #f02425;
  content: "\f00d";
}

.quiz-result
  .result-statistic
  .result-statistic-field.result-questions-skipped
  label::before {
  color: #ddd;
  content: "\f2f5";
}

.quiz-status {
  position: sticky;
  z-index: 99;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 0 35px 0;
}

.quiz-status > div {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  max-width: 792px;
  height: 60px;
  margin: 0 auto;
  padding: 5px 10px 5px 30px;
  border-radius: 4px;
  background: var(--lp-primary-color);
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .quiz-status > div {
    display: block;
    height: auto;
    text-align: center;
  }
}

.quiz-status > div > div {
  display: flex;
  flex-direction: row-reverse;
  flex: 0 0 50%;
  align-items: center;
}

.quiz-status > div .current-point {
  display: none;
}

.quiz-status .questions-index {
  display: inline-block;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.quiz-status .questions-index span {
  color: #333;
  font-weight: 500;
}

.quiz-status .countdown {
  position: relative;
  min-width: 120px;
  margin-right: 5px;
  padding: 12px 29px;
  color: #333;
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
}

.quiz-status .countdown .fas {
  position: absolute;
  top: 50%;
  left: 10px;
  color: #333;
  font-size: 14px;
  transform: translateY(-50%);
}

.quiz-status .countdown .clock {
  display: none;
  width: 40px;
  height: 40px;
}

.quiz-status .countdown .clock::before {
  position: absolute;
  width: 40px;
  height: 40px;
  border: 4px solid #b1c1e6;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.quiz-status .countdown .clock .circle-progress-bar {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  stroke: #5383f7;
}

.quiz-status
  .countdown
  .clock
  .circle-progress-bar
  .circle-progress-bar__circle {
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.quiz-status .countdown .clock.x .circle-progress-bar__circle {
  stroke: #f00;
}

.quiz-status .submit-quiz button {
  margin: 0 0 0 10px;
  border: none;
  border-radius: 0;
  background: #fff;
  text-transform: uppercase;
}

.quiz-status .submit-quiz button:hover {
  background: var(--lp-secondary-color);
}

.quiz-status.submitting .submit-quiz button {
  background: #ddd;
}

.question-numbers {
  text-align: center;
  list-style: none;
}

.question-numbers li {
  display: inline-block;
  position: relative;
  margin-bottom: 3px;
}

.question-numbers li a {
  display: block;
  min-width: 20px;
  padding: 8px;
  border: 1px solid #ddd;
  color: #999;
  background: #f5f5f5;
  box-shadow: none;
  font-size: 12px;
  line-height: 1;
}

.question-numbers li a span {
  vertical-align: middle;
}

.question-numbers li a:hover {
  border: 1px solid #3880a2;
  color: #fff;
  background: #00adff;
}

.question-numbers li.current a {
  border-color: #3880a2;
  color: #fff !important;
  background: #00adff;
}

.question-numbers li.current.skipped::after {
  background: #fff;
}

.question-numbers li.answered a::after {
  margin-left: 3px;
  font-family: "Font Awesome 5 Free";
  font-size: 8px;
  vertical-align: middle;
}

.question-numbers li.answered.answered-wrong a {
  color: #f00;
}

.question-numbers li.answered.answered-true a {
  color: #00adff;
}

.question-numbers li.answered.answered-true.current a {
  color: #fff;
}

.question-numbers li.skipped::after {
  position: absolute;
  bottom: 3px;
  left: 50%;
  width: 10px;
  height: 4px;
  margin-left: -5px;
  border-radius: 2px;
  background: #aaa;
  content: "";
}

.quiz-intro {
  display: flex;
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  flex-flow: row wrap;
}

@media (max-width: 768px) {
  .quiz-intro {
    padding-right: 10px;
    padding-left: 10px;
    justify-content: space-between;
  }
}

.quiz-intro-item {
  display: flex;
  margin: 0 40px 0 0;
  align-items: center;
}

@media (max-width: 768px) {
  .quiz-intro-item {
    margin: 0 20px 0 0;
  }
}

.quiz-intro-item::before {
  position: relative;
  top: -2px;
  margin-right: 10px;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 900;
}

.quiz-intro-item--passing-grade {
  order: 2;
}

.quiz-intro-item--passing-grade::before {
  content: "\f012";
}

.quiz-intro-item--questions-count {
  order: 1;
}

.quiz-intro-item--questions-count::before {
  content: "\f12e";
}

.quiz-intro-item--duration {
  order: 2;
}

.quiz-intro-item--duration::before {
  content: "\f017";
}

.quiz-intro-item__title {
  margin: 0;
  padding: 0 8px 0 0;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.7;
}

.quiz-intro-item__content {
  color: #222;
  font-size: 16px;
  font-weight: 400;
}

.question-explanation-content,
.question-hint-content {
  margin-bottom: 20px;
  padding: 10px 15px;
  background: #f5f5f5;
}

.redo-quiz button[type="submit"] {
  content: attr(data-counter);
}

.circle-bar {
  position: relative;
  width: 300px;
  height: 300px;
  border-color: #ddd;
}

.circle-bar::before {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 10px solid #ddd;
  border-radius: 50%;
  content: "";
}

.circle-bar .before,
.circle-bar .after {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 10px solid #14c4ff;
  border-radius: 50%;
}

.circle-bar .before {
  border-bottom-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.circle-bar .after {
  border-color: #14c4ff;
  border-top-color: transparent;
  border-right-color: transparent;
  transform: rotate(45deg);
}

.circle-bar.bg50 .after {
  z-index: 10;
  border-bottom-color: inherit;
  border-left-color: inherit;
  transform: rotate(45deg);
}

.lp-quiz-buttons .complete-quiz,
.lp-quiz-buttons .back-quiz,
.lp-quiz-buttons .review-quiz {
  float: right;
}

.quiz-results,
.quiz-content,
.quiz-questions,
.quiz-buttons,
.quiz-attempts {
  margin-bottom: 60px;
}

.quiz-result .result-grade {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  height: 200px;
  margin-bottom: 30px;
  padding: 50px;
  justify-content: center;
  align-items: center;
}

.quiz-result .result-grade::before,
.quiz-result .result-grade svg {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-left: -100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.quiz-result .result-grade::before {
  box-sizing: border-box;
  padding: 30px;
  border: 10px solid #ccc;
  content: "";
}

.quiz-result .result-grade svg {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.quiz-result .result-grade svg circle {
  stroke: var(--lp-primary-color);
}

.quiz-result.passed .result-grade svg {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.quiz-result.passed .result-grade .result-achieved {
  color: #333;
}

.quiz-result.passed .result-grade .result-message strong {
  color: #4caf50;
}

.quiz-questions .question {
  margin-bottom: 60px;
}

.question .answer-option {
  padding: 0;
  _border: 2px solid #d9e0f1;
  background: #fff;
}

.question .answer-option input[type="radio"],
.question .answer-option input[type="checkbox"] {
  position: absolute;
  top: 50%;
  margin: -19px 0 0 10px;
  border-color: #d9e0f1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.question .answer-option input[type="radio"]::after,
.question .answer-option input[type="checkbox"]::after {
  border-color: #d9e0f1;
}

.question .answer-option input[type="radio"]:disabled,
.question .answer-option input[type="checkbox"]:disabled {
  border-color: #ddd;
  background: #f9f9f9;
}

.question .answer-option input[type="radio"]:disabled::after,
.question .answer-option input[type="checkbox"]:disabled::after {
  border-color: #ddd;
}

.question .answer-option input[type="radio"]:checked:not(:disabled)::after,
.question .answer-option input[type="checkbox"]:checked:not(:disabled)::after {
  border-color: #99aee4;
}

.question .answer-option input[type="radio"] {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.question .answer-option input[type="radio"]::before {
  border-radius: 50%;
}

.question .answer-option .option-title {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 14px 10px 11px 65px;
  border: 1px solid #ccc;
  color: #666;
  background: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.question .answer-option .option-title::after {
  display: inline-block;
  visibility: hidden !important;
  content: ".";
}

.question .answer-option .option-title:hover {
  background: #f9f9f9;
}

.question .answer-option .option-title::before {
  display: none;
}

.question .answer-option.answer-correct .option-title {
  border-color: #4caf50;
}

.question .answer-option.answered-wrong .option-title {
  border-color: #ff6423;
}

.question .question-title {
  display: block;
  margin-top: 0;
  margin-bottom: 18px;
  color: #333;
  font-size: 18px;
  font-weight: normal;
  font-weight: 500;
}

.question .question-title .edit-link {
  float: right;
  font-size: 14px;
  font-weight: normal;
}

.question .question-content {
  margin-bottom: 30px;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

.question .question-content img {
  width: 100%;
  max-width: 100%;
}

.question .question-response {
  margin-bottom: 10px;
  font-size: 14px;
}

.question .question-response .label {
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 8px 10px;
  border-radius: 4px;
  color: #fff;
  line-height: 1;
}

.question .question-response .point {
  display: inline-block;
}

.question .question-response.correct .label {
  background: #4caf50;
}

.question .question-response.incorrect .label {
  background: #ff6423;
}

.question .question-index {
  display: inline-block;
  margin: 0 5px 0 0;
}

.question .btn-show-hint {
  position: relative;
  margin: 0 0 0 10px;
  padding: 0;
  outline: none;
  color: #00adff;
  background: transparent;
  font-size: 0;
  text-decoration: none;
}

.question .btn-show-hint::before {
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  content: "\f059";
}

.question .btn-show-hint:hover span {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: auto;
  padding: 6px 9px;
  border-radius: 2px;
  color: #333;
  background: #eee;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  text-transform: none;
}

.question.question-fill_in_blanks .blanks .blank-input-wrap {
  display: inline-block;
}

.question.question-fill_in_blanks .blanks .blank-block {
  margin-bottom: 20px;
}

.question.question-fill_in_blanks .blanks .blank-input {
  display: inline-block;
  width: auto;
  min-width: 50px;
  margin: 0 10px;
  padding: 0 10px;
  border: none;
  border-bottom: 1px dashed #ddd;
  text-align: center;
  white-space: nowrap;
}

.question.question-fill_in_blanks .blanks .blank-input br {
  display: none;
}

.question.question-fill_in_blanks .blanks .blank-input > * {
  display: inline;
  white-space: nowrap;
}

.question.question-fill_in_blanks .blanks .blank-select {
  display: inline-block;
  height: 30px;
  margin-left: 4px;
  padding: 1px;
}

.question.question-fill_in_blanks .blanks .blank-fills {
  display: inline-block;
}

.question.question-fill_in_blanks .blanks .blank-fills code {
  margin: 0 5px;
  padding: 0 6px;
  background: #ddd;
  line-height: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.question.question-fill_in_blanks .blanks.ordered {
  list-style-position: inside;
}

.question.question-fill_in_blanks .blanks.one-paragraph {
  line-height: 3;
}

.lp-sort-bg label.option-title {
  background: rgba(255, 182, 6, 0.68) !important;
}

.learn-press-message.fixed {
  position: fixed;
  top: 32px;
  left: 0;
  right: 0;
  background: rgba(0, 173, 255, 0.6);
  text-align: center;
  z-index: 100;
  color: #fff;
  padding: 10px;
}

.learn-press-message.fixed[data-delay-in] {
  display: none;
}

/**
 * CSS for overriding some style defaults of themes
 */
/**/
body.learnpress-page.twentysixteen #primary .lp-entry-content {
  float: none;
  width: auto;
  margin: 0 15%;
}

body.learnpress-page.twentyfifteen .course-summary-sidebar .widget {
  width: 100%;
  padding: 0;
}

body.learnpress-page.twentysixteen .entry-footer {
  display: none;
}

body.learnpress-page.twentysixteen .lp-entry-content {
  float: none;
  width: auto;
}

body.learnpress-page.twentyseventeen #primary article.type-page {
  width: 100%;
}

@media screen and (min-width: 61.5625em) {
  body.twentysixteen.learnpress-page .entry-footer {
    display: none;
  }
  body.twentysixteen.learnpress-page .lp-entry-content {
    float: none;
    width: auto;
  }
  body:not(.search-results) article:not(.type-page) .entry-footer {
    display: none;
  }
  body:not(.search-results) article:not(.type-page) .lp-entry-content {
    float: none;
    width: auto;
  }
}

body.twentyseventeen.learnpress-page #primary article.page .entry-header,
body.twentyseventeen.learnpress-page #primary article.page .lp-entry-content {
  width: 100%;
  float: none;
}

@media screen and (min-width: 48em) {
  body.twentyseventeen.learnpress-page #primary article.page .entry-header,
  body.twentyseventeen.learnpress-page #primary article.page .lp-entry-content {
    width: 100%;
    float: none;
  }
}

.learn-press-tip {
  display: none;
  margin: 0 5px;
  color: #444;
  font-size: 13px;
  font-style: normal;
  line-height: 1;
  vertical-align: baseline;
  cursor: pointer;
}

.learn-press-tip.ready {
  display: inline-block;
}

.learn-press-tip::before {
  font-family: "Font Awesome 5 Free";
  content: "\f059";
}

.learn-press-tip:hover {
  opacity: 0.8;
}

.learn-press-tip-floating {
  position: absolute;
  z-index: 9999999;
  min-width: 20px;
  margin-left: -1px;
  padding: 0.618em 1em;
  color: #fff;
  background: #383838;
  font-size: 12px;
  line-height: 1.2em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.learn-press-tip-floating > * {
  font-size: 12px;
}

.learn-press-tip-floating .close {
  display: inline-block;
  position: absolute;
  top: -8px;
  right: -8px;
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  color: #fff;
  background: #468fbc;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.learn-press-tip-floating .close::before {
  font-family: "Font Awesome 5 Free";
  content: "\f00d";
}

.learn-press-tip-floating p {
  margin: 0;
}

.learn-press-tip-floating::before {
  position: absolute;
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -6px;
  border: 6px solid transparent;
  border-top-color: #383838;
  border-bottom-width: 0;
  content: "";
}

body.lp-preview.admin-bar #learn-press-content-item {
  top: 32px !important;
}

body.lp-preview #learn-press-course-curriculum {
  display: none;
}

body.lp-preview #learn-press-content-item {
  top: 0 !important;
  left: 0 !important;
}

body.lp-preview #course-item-content-header {
  display: none;
}

/**
 * CSS for jAlerts
 */
/**
 * Mixin
 */
@-webkit-keyframes rotating4 {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating4 {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes animation4 {
  from {
    left: -40%;
    width: 40%;
  }
  to {
    left: 100%;
    width: 10%;
  }
}

@keyframes animation4 {
  from {
    left: -40%;
    width: 40%;
  }
  to {
    left: 100%;
    width: 10%;
  }
}

#popup_container {
  opacity: 0;
  transform: scale(0.5);
}

body.confirm #popup_overlay {
  z-index: 999998 !important;
}

body.confirm #popup_container {
  z-index: 999999 !important;
  max-width: 90% !important;
  min-width: 300px !important;
  padding: 10px !important;
  background: #f5f5f5;
  transition: opacity 0.25s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

body.confirm #popup_container #popup_title {
  display: none !important;
}

body.confirm #popup_container #popup_message {
  margin: -10px;
  background: #fff;
  padding: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

body.confirm #popup_container .close {
  position: absolute;
  top: 3px;
  right: 10px;
}

body.confirm #popup_container #popup_panel {
  margin-top: 20px;
  text-align: center;
}

body.confirm #popup_container #popup_panel button,
body.confirm #popup_container #popup_panel input[type="button"],
body.confirm #popup_container #popup_panel input[type="submit"] {
  height: 30px;
  line-height: 30px;
  padding: 0 25px;
}

body.confirm #popup_container #popup_cancel {
  display: none;
}

body.confirm #popup_container.ready {
  opacity: 1;
  transform: scale(1);
}

/**
 * Archive courses page.
 */
a {
  text-decoration: none;
}

.lp-content-area {
  width: 1170px;
  max-width: 100%;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .lp-content-area {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.lp-content-area::after,
.lp-content-area::before {
  display: table;
  clear: both;
  content: "";
}

.lp-archive-courses.loading ul.learn-press-courses {
  position: relative;
}

.lp-archive-courses.loading ul.learn-press-courses::after {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f3f3f3;
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;
  list-style: none;
  content: "";
  animation: lp-skeleton-loading 1.4s ease infinite;
}

.lp-archive-courses.loading ul.learn-press-courses::before {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8%;
  background: #fff;
  content: "";
}

.lp-archive-courses form[name="search-course"] {
  position: relative;
  margin-bottom: 20px;
}

.lp-archive-courses form[name="search-course"] .search-course-input {
  width: 100%;
  padding: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.lp-archive-courses form[name="search-course"] .search-course-button {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  height: auto;
  padding: 15px;
  line-height: 1px;
}

.lp-archive-courses .learn-press-courses-header {
  float: left;
  padding-top: 4px;
}

.lp-archive-courses .learn-press-courses-header h1 {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .lp-archive-courses .learn-press-courses-header {
    margin-bottom: 20px;
  }
}

.lp-archive-courses .learn-press-courses {
  clear: both;
  margin: 0 -15px 0 -15px;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lp-archive-courses .learn-press-courses .course {
  width: 25%;
  margin: 0 0 30px 0;
  box-shadow: none;
  list-style: none;
}

.lp-archive-courses .learn-press-courses .course .course-item {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  transition: all 0.3s;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail {
  overflow: hidden;
  position: relative;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .lp-badge.featured {
  position: absolute;
  z-index: 100;
  top: 28px;
  left: -110px;
  width: 300px;
  text-align: center;
  transform: rotate(-45deg);
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .course-thumbnail
  a {
  display: block;
  overflow: hidden;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .course-thumbnail
  .thumbnail {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: unset;
  line-height: unset;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .course-thumbnail
  .thumbnail::before {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  transition: all 0.3s;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .course-thumbnail
  .thumbnail
  img {
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.5s ease;
  transform: scale(1);
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .course-wishlist {
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 6px;
  opacity: 0;
  color: #fff;
  cursor: pointer;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .course-wishlist::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f004";
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .course-wishlist
  .course-wishlist__btn {
  display: none;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail
  .course-wishlist:hover {
  color: var(--lp-primary-color);
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail:hover::before {
  opacity: 1;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail:hover
  .course-thumbnail
  a
  img {
  transform: scale(1.05);
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wrap-thumbnail:hover
  .course-wishlist {
  opacity: 1;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item:hover
  .course-thumbnail
  .thumbnail::before {
  opacity: 1;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item:hover
  .course-thumbnail
  a
  img {
  transform: scale(1.05);
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item:hover
  .course-wishlist {
  opacity: 1;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-wishlist.filled {
  opacity: 1;
}

.lp-archive-courses .learn-press-courses .course .course-item .course-content {
  position: relative;
  border-top: 0;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-permalink {
  display: -webkit-box;
  clear: both;
  overflow: hidden;
  position: relative;
  border: none;
  color: #fff;
  text-decoration: none;
  text-transform: none;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-permalink:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-permalink
  .course-title {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-permalink
  .course-title:hover {
  color: var(--lp-primary-color);
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-review {
  display: none;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-categories,
.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-tags {
  display: block;
  font-size: 14px;
  font-weight: 300;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-categories {
  float: left;
  position: relative;
  margin-right: 10px;
  margin-bottom: 16px;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-categories
  a {
  display: inline-block;
  display: none;
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-categories
  a:first-child {
  display: block;
  margin-left: 0;
  padding: 2px 16px;
  background: var(--lp-primary-color);
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-categories
  a:hover {
  color: #333;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-categories::after {
  position: absolute;
  top: 50%;
  right: -2px;
  width: 1px;
  height: 12px;
  opacity: 0.5;
  background: #999;
  content: "";
  transform: translate(0, -50%);
  display: none;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-instructor {
  float: left;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  text-transform: capitalize;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-instructor
  a {
  color: #059de9;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-instructor
  a:hover {
  color: var(--lp-primary-color);
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-info {
  color: #666;
  font-size: 14px;
  font-weight: 300;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-info
  > span {
  display: block;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-info
  .course-price {
  float: right;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-info
  .course-price
  .origin-price,
.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-info
  .course-price
  .price {
  color: #333;
  font-size: 16px;
  font-weight: 600;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-info
  .course-price
  .origin-price {
  margin-right: 10px;
  color: #666;
  font-size: 14px;
  font-weight: 300;
  text-decoration: line-through;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-info
  .course-instructor {
  float: right;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-info::after {
  display: block;
  clear: both;
  content: "";
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .course-excerpt {
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 28px;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.lp-archive-courses
  .learn-press-courses
  .course
  .course-item
  .course-content
  .separator {
  display: none;
}

.lp-archive-courses .learn-press-courses[data-layout="list"] {
  margin: 0;
}

.lp-archive-courses .learn-press-courses[data-layout="list"] .course {
  display: flex;
  width: 100%;
  padding: 24px;
  border: 1px solid rgba(204, 204, 204, 0.3);
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .lp-archive-courses .learn-press-courses[data-layout="list"] .course {
    padding: 15px;
  }
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: unset;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item {
    flex-direction: column;
  }
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-wrap-thumbnail {
  float: left;
  width: 35.8%;
}

@media (max-width: 767px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-wrap-thumbnail {
    width: 100%;
  }
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content {
  float: right;
  width: calc(100% - 35.8% - 76px);
  padding-top: 5px;
}

@media (max-width: 1200px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content {
    width: calc(100% - 35.8% - 40px);
  }
}

@media (max-width: 1200px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content {
    width: calc(100% - 35.8% - 25px);
  }
}

@media (max-width: 767px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content {
    width: 100%;
    padding-top: 20px;
  }
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-categories
  a {
  position: relative;
  padding: 2px 30px 2px 15px;
  -webkit-clip-path: polygon(0% 0%, 100% 0, 88% 50%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0, 88% 50%, 100% 100%, 0% 100%);
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-permalink {
  overflow: hidden;
  height: 25px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-permalink
  .course-title {
  color: #333;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-permalink
  .course-title:hover {
  color: var(--lp-primary-color);
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-wrap-meta {
  display: flex;
  padding-top: 12px;
  padding-bottom: 18px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-wrap-meta
  .meta-item {
  padding-right: 28px;
  text-transform: capitalize;
}

@media (max-width: 1024px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content
    .course-wrap-meta
    .meta-item {
    padding-right: 20px;
  }
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-wrap-meta
  .meta-item::before {
  position: relative;
  top: -1px;
  margin-right: 8px;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-wrap-meta
  .meta-item-level::before {
  content: "\f012";
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-wrap-meta
  .meta-item-duration::before {
  content: "\f017";
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-wrap-meta
  .meta-item-lesson::before {
  content: "\f0c5";
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-wrap-meta
  .meta-item-quiz::before {
  content: "\f12e";
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-wrap-meta
  .meta-item-student::before {
  content: "\f501";
}

@media (max-width: 815px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content
    .course-wrap-meta {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content
    .course-wrap-meta
    .meta-item {
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content
    .course-wrap-meta {
    grid-template-columns: repeat(3, 1fr);
  }
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-tags
  a {
  margin: 0 10px 10px 0;
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
  background: #e4a2a2;
  line-height: 1;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-footer {
  float: left;
  margin-right: 20px;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-footer
  .course-students {
  display: none;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-footer
  .course-price {
  float: unset;
}

@media (max-width: 767px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content
    .course-footer {
    margin-bottom: 30px;
  }
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-readmore {
  float: right;
  position: relative;
  right: 4px;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-readmore
  a {
  padding: 9px 24px;
  border-radius: 3px;
  color: #fff;
  background: var(--lp-primary-color);
  font-weight: 400;
  line-height: 1;
  transition: all 0.3s;
}

.lp-archive-courses
  .learn-press-courses[data-layout="list"]
  .course
  .course-item
  .course-content
  .course-readmore
  a:hover {
  opacity: 0.5;
  background: var(--lp-primary-color);
}

@media (max-width: 767px) {
  .lp-archive-courses
    .learn-press-courses[data-layout="list"]
    .course
    .course-item
    .course-content
    .course-readmore {
    display: flex;
    float: unset;
    clear: both;
  }
}

.lp-archive-courses .learn-press-courses[data-layout="grid"] .course {
  padding: 0 15px;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item:hover {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.2);
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content {
  padding: 15px 20px 12px 20px;
  border: 1px solid #f0f0f0;
  border-top: 0;
  flex-grow: 1;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-instructor {
  margin-bottom: 7px;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-categories {
  float: right;
  position: absolute;
  z-index: 1;
  top: -34px;
  right: -1px;
  margin-right: 0;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-categories::after {
  display: none;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-categories
  a {
  position: relative;
  margin: 0;
  padding: 2px 12px 2px 22px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 12px 50%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 12px 50%);
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-permalink {
  height: 48px;
  margin-bottom: 20px;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-wrap-meta {
  display: none;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-info
  .course-excerpt {
  display: none;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-info
  .course-footer {
  padding-top: 18px;
  border-top: 1px solid rgba(153, 153, 153, 0.2);
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-info
  .course-footer
  .course-students {
  float: left;
  text-transform: capitalize;
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-info
  .course-footer
  .course-students::before {
  position: relative;
  top: -1px;
  margin-right: 8px;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f501";
}

.lp-archive-courses
  .learn-press-courses[data-layout="grid"]
  .course
  .course-item
  .course-content
  .course-info
  .course-readmore {
  display: none;
}

@media (max-width: 992px) {
  .lp-archive-courses .learn-press-courses[data-layout="grid"] .course {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .lp-archive-courses .learn-press-courses[data-layout="grid"] .course {
    width: 100%;
  }
}

.lp-archive-courses .learn-press-courses[data-size="3"] .course {
  width: 33.3333%;
}

@media (max-width: 767px) {
  .lp-archive-courses .learn-press-courses[data-size="3"] .course {
    width: 100%;
  }
}

.lp-courses-bar {
  display: flex;
  float: right;
  margin-bottom: 48px;
}

@media (max-width: 767px) {
  .lp-courses-bar {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}

.lp-courses-bar .search-courses {
  position: relative;
  width: 306px;
  max-width: 100%;
  margin-right: 20px;
  line-height: 1;
  flex: 1;
}

.lp-courses-bar .search-courses input[type="text"] {
  width: 100%;
  height: 44px;
  padding: 6px 32px 6px 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  font-size: 14px;
  font-style: italic;
  line-height: 1;
}

.lp-courses-bar .search-courses input[type="text"]:focus {
  border-color: var(--lp-primary-color);
  outline: 0;
}

.lp-courses-bar .search-courses button {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  color: #333;
  background: transparent;
  font-size: 16px;
  line-height: 1;
  transform: translate(0, -50%);
}

.lp-courses-bar .search-courses button:focus {
  border: 0;
  outline: 0;
}

.lp-courses-bar .search-courses button.loading > i::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f110";
  -webkit-animation: lp-rotating 1s linear infinite;
  -moz-animation: lp-rotating 1s linear infinite;
  animation: lp-rotating 1s linear infinite;
}

.lp-courses-bar .search-courses ::-webkit-input-placeholder {
  color: #999;
}

.lp-courses-bar .search-courses :-ms-input-placeholder {
  color: #999;
}

.lp-courses-bar .search-courses ::placeholder {
  color: #999;
}

@media (max-width: 767px) {
  .lp-courses-bar .search-courses {
    width: 100%;
  }
}

.lp-courses-bar .switch-layout {
  display: flex;
  height: 44px;
  line-height: 1;
  text-align: right;
  flex: 0 0 40px;
  align-items: center;
  justify-content: center;
}

.lp-courses-bar .switch-layout input[type="radio"] {
  display: none;
}

.lp-courses-bar .switch-layout .switch-btn {
  display: block;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  font-size: 16px;
  line-height: 40px;
  text-align: left;
  text-align: center;
  cursor: pointer;
}

.lp-courses-bar .switch-layout .switch-btn::before {
  display: inline-block;
  margin: 8px 6px;
  color: #333;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  line-height: 1;
}

.lp-courses-bar .switch-layout .switch-btn.grid::before {
  content: "\f00a";
}

.lp-courses-bar .switch-layout .switch-btn.list {
  margin-left: 3px;
}

.lp-courses-bar .switch-layout .switch-btn.list::before {
  content: "\f03a";
}

input[type="radio"]:nth-child(1):checked ~ .switch-btn:nth-child(2) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(1):checked ~ .switch-btn:nth-child(2)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(3):checked ~ .switch-btn:nth-child(4) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(3):checked ~ .switch-btn:nth-child(4)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(5):checked ~ .switch-btn:nth-child(6) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(5):checked ~ .switch-btn:nth-child(6)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(7):checked ~ .switch-btn:nth-child(8) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(7):checked ~ .switch-btn:nth-child(8)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(9):checked ~ .switch-btn:nth-child(10) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(9):checked ~ .switch-btn:nth-child(10)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(11):checked ~ .switch-btn:nth-child(12) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(11):checked ~ .switch-btn:nth-child(12)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(13):checked ~ .switch-btn:nth-child(14) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(13):checked ~ .switch-btn:nth-child(14)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(15):checked ~ .switch-btn:nth-child(16) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(15):checked ~ .switch-btn:nth-child(16)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(17):checked ~ .switch-btn:nth-child(18) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(17):checked ~ .switch-btn:nth-child(18)::before {
  color: var(--lp-primary-color);
}

input[type="radio"]:nth-child(19):checked ~ .switch-btn:nth-child(20) {
  background: #f5f5f5;
}

input[type="radio"]:nth-child(19):checked ~ .switch-btn:nth-child(20)::before {
  color: var(--lp-primary-color);
}

/**
 *  End Archive courses page.
 */
/**
 *  Single course page
*/
.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info {
  margin-bottom: 60px;
  padding: 40px 0 10px 0;
  color: #fff;
  /* background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #fedb37 30%,
      #fdb931 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    ); */
  background: #72cdf4;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left {
  width: 70%;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-title {
  margin-bottom: 42px;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta.course-meta-primary {
  margin-bottom: 36px;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta.course-meta-primary
  .course-meta__pull-left
  .meta-item-categories::before {
  font-weight: 400;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta.course-meta-secondary {
  margin-bottom: 0;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item {
  display: flex;
  height: 46px;
  margin-right: 50px;
  font-size: 16px;
  font-weight: 300;
  text-transform: capitalize;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item
  span {
  padding-right: 4px;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item:last-child {
  margin-right: 0;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item::before {
  margin-right: 8px;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item
  .meta-item__image {
  margin-right: 10px;
  flex: 0 0 46px;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item
  .meta-item__image
  img {
  overflow: hidden;
  position: relative;
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item
  .meta-item__value
  label {
  margin: 0;
  color: #999;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item
  .meta-item__value
  > div {
  line-height: 1.5;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item
  .meta-item__value
  > div
  a {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item
  .meta-item__value
  > div
  a:hover {
  color: var(--lp-primary-color);
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-categories {
  align-items: center;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-categories::before {
  margin-right: 10px;
  font-size: 36px;
  content: "\f02e";
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-categories
  .meta-item__value
  div
  span {
  padding: 0 5px;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-categories
  .meta-item__value
  div
  a:hover {
  color: var(--lp-primary-color);
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-review
  .meta-item__value {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-review
  .meta-item__value
  .review-stars-rated {
  margin-bottom: 0;
  line-height: 1;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-duration::before {
  content: "\f017";
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-level::before {
  content: "\f012";
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-lesson::before {
  content: "\f0c5";
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-quiz::before {
  content: "\f12e";
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .course-detail-info
  .course-info-left
  .course-meta
  .course-meta__pull-left
  .meta-item.meta-item-student::before {
  content: "\f501";
}

@media (max-width: 1024px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .course-detail-info
    .course-info-left
    .course-meta
    .course-meta__pull-left
    .meta-item {
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .course-detail-info
    .course-info-left
    .course-meta
    .course-meta__pull-left
    .meta-item {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .course-detail-info
    .course-info-left
    .course-meta
    .course-meta__pull-left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}

@media (max-width: 768px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .course-detail-info
    .course-info-left {
    width: 100%;
  }
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area {
  display: flex;
  flex-direction: row;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .entry-content-left {
  width: calc(100% - 300px);
  max-width: 100%;
  margin: 0;
  padding-right: 40px;
}

@media (max-width: 768px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .lp-entry-content.lp-content-area
    .entry-content-left {
    width: 100%;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .lp-entry-content.lp-content-area
    .entry-content-left {
    width: 100%;
    margin-bottom: 30px;
  }
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar {
  width: 300px;
  max-width: 100%;
  margin-top: -272px;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner {
  width: 300px;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  .course-sidebar-top {
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.05);
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  .course-sidebar-secondary {
  margin-top: 30px;
  padding: 0;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  .learn-press-course-wishlist {
  color: #fff;
  background: transparent !important;
  font-size: 0;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  .learn-press-course-wishlist.on,
.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  .learn-press-course-wishlist:hover {
  color: var(--lp-primary-color);
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  .learn-press-course-wishlist::before {
  font-family: "Font Awesome 5 Free";
  font-size: 26px;
  font-weight: 900;
  content: "\f004";
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  > div {
  overflow: hidden;
  padding: 20px;
  background: #fff;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  > div
  ul {
  list-style: none;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  > div
  ul
  li
  a {
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.lp-archive-courses
  .course-summary
  .course-summary-content
  .lp-entry-content.lp-content-area
  .course-summary-sidebar
  .course-summary-sidebar__inner
  > div
  ul
  li
  a:hover {
  color: var(--lp-primary-color);
  box-shadow: unset;
}

@media (max-width: 768px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .lp-entry-content.lp-content-area
    .course-summary-sidebar {
    width: 100%;
    margin-top: 0;
  }
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .lp-entry-content.lp-content-area
    .course-summary-sidebar
    .course-summary-sidebar__inner {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .lp-entry-content.lp-content-area
    .course-summary-sidebar {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  .lp-archive-courses
    .course-summary
    .course-summary-content
    .lp-entry-content.lp-content-area {
    flex-direction: column;
  }
}

.lp_allow_repuchase_select {
  padding: 20px;
  background: #f7f7f7;
}

.lp_allow_repuchase_select > ul {
  padding: 0;
  list-style: none;
}

.lp_allow_repuchase_select > a {
  display: inline-block;
  margin-top: 10px;
  color: #fff;
  background-color: #222;
}

#learn-press-course .course-summary-sidebar .course-sidebar-preview {
  margin-bottom: 35px;
  background: #fff;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-price {
  text-align: center;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-price
  .origin-price {
  color: #666;
  font-size: 18px;
  font-weight: 300;
  font-style: unset;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-price
  .price {
  color: #333;
  font-size: 26px;
  font-weight: 500;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .media-preview {
  overflow: hidden;
  position: relative;
  margin: -20px -20px 40px;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .media-preview
  img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons {
  margin-bottom: 34px;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  > * {
  margin-bottom: 10px;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  > *:last-child {
  margin-bottom: 0;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  form,
#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  button {
  width: 100%;
  text-decoration: none;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  button {
  font-weight: 500;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  button:hover {
  border-color: var(--lp-primary-color);
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  button.button-enroll-course {
  border-color: var(--lp-primary-color);
  color: #fff;
  background: var(--lp-primary-color);
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  button.button-enroll-course:hover {
  opacity: 0.5;
  background: var(--lp-primary-color);
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  button.button-enroll-course.loading::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f110";
  animation: lp-rotating 1s linear infinite;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  button
  ~ .lp-enroll-notice {
  margin-top: 20px;
  padding: 20px;
  border: 2px solid #059601;
  border-radius: 9px;
  font-size: 15px;
  line-height: 1.4;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .lp-course-buttons
  button
  ~ .lp-enroll-notice.error {
  border-color: #d85554;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-time {
  margin-bottom: 28px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-time
  .course-time-row {
  display: flex;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  justify-content: space-between;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-time
  .course-time-row
  strong {
  color: #333;
  font-weight: 500;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-time
  .course-time-row
  time {
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-results-progress
  .items-progress,
#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-results-progress
  .course-progress {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  flex-wrap: wrap;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-results-progress
  .items-progress__heading,
#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-results-progress
  .course-progress__heading {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  flex: 1 1 auto;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-results-progress
  .items-progress
  .number,
#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-results-progress
  .course-progress
  .number {
  display: block;
  margin: 0;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  text-align: right;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-results-progress
  .items-progress
  .learn-press-progress,
#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  .course-results-progress
  .course-progress
  .learn-press-progress {
  clear: both;
  width: 100%;
}

#learn-press-course
  .course-summary-sidebar
  .course-sidebar-preview
  > *:last-child {
  margin-bottom: 0;
}

.lp-entry-content .lp-button.secondary {
  background: #9198ab;
}

.lp-entry-content .lp-button.secondary:hover {
  background: #9ba6c5;
}

.lp-entry-content .lp-button:disabled,
.lp-entry-content .lp-button[disabled] {
  background: #ddd;
  pointer-events: none;
}

.lp-entry-content:hover {
  text-decoration: none;
}

#learn-press-course-curriculum .section-title {
  position: relative;
  margin-bottom: 0;
  padding: 8px 0;
  color: #5383f7;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
}

#learn-press-course-curriculum .section-title span.show-desc {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 20px;
  height: 20px;
  transform: translate(0, -50%);
}

#learn-press-course-curriculum .section-title span.show-desc::before {
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 900;
  content: "\f107";
}

#learn-press-course-curriculum .section-title span.show-desc:hover::before {
  border-top-color: #ccc;
}

#popup-course {
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}

#popup-course .back-course {
  padding-left: 30px;
  padding-right: 30px;
  line-height: 70px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

#popup-course .back-course:hover {
  background: rgba(255, 255, 255, 0.15);
}

@media (max-width: 768px) {
  #popup-course .back-course {
    background: transparent;
    line-height: 50px;
    opacity: 0.6;
    padding-left: 15px;
    padding-right: 15px;
  }
  #popup-course .back-course:hover {
    background: transparent;
    opacity: 1;
  }
}

#popup-course #popup-header {
  display: flex;
  position: fixed;
  z-index: 100;
  right: 0;
  left: 475px;
  padding: 0;
  border-bottom: 1px solid #d9e0f1;
  background: var(--lp-secondary-color);
  -webkit-transition: left 0.25s;
  -moz-transition: left 0.25s;
  -ms-transition: left 0.25s;
  -o-transition: left 0.25s;
  transition: left 0.25s;
}

#popup-course #popup-header .popup-header__inner {
  display: flex;
  width: 792px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1024px) {
  #popup-course #popup-header .popup-header__inner {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  #popup-course #popup-header .popup-header__inner {
    flex-direction: column;
  }
}

#popup-course #popup-header .popup-header__inner .course-title {
  margin: 0 20px;
  padding: 0;
  line-height: 70px;
  letter-spacing: unset;
}

#popup-course #popup-header .popup-header__inner .course-title a {
  display: -webkit-box;
  overflow: hidden;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#popup-course #popup-header .popup-header__inner .course-title a:hover {
  color: var(--lp-primary-color);
}

@media (max-width: 768px) {
  #popup-course #popup-header .popup-header__inner .course-title {
    flex: 1;
  }
}

#popup-course #popup-header .popup-header__inner .items-progress {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  #popup-course #popup-header .popup-header__inner .items-progress {
    flex: 1;
  }
}

@media (max-width: 767px) {
  #popup-course #popup-header .popup-header__inner .items-progress {
    padding-bottom: 20px;
    justify-content: end;
  }
}

#popup-course #popup-header .popup-header__inner .items-progress .number {
  padding-right: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

#popup-course #popup-header .lp-button {
  display: flex;
  position: relative;
  height: 40px;
  margin: 9px;
  border: none;
  color: #fff;
  background: var(--lp-primary-color);
  font-size: 14px;
  font-weight: 400;
  justify-content: center;
  line-height: 40px;
  align-items: center;
}

#popup-course #popup-header .lp-button:hover {
  opacity: 0.8;
}

#popup-course #popup-header .lp-button-back {
  float: right;
  margin: 9px 10px;
}

#popup-course #popup-header .lp-button-back button::before,
#popup-course #popup-header .lp-button-back button::after {
  content: "";
}

#popup-course #popup-header .lp-button-back button::before {
  border: 1px solid #ddd;
}

#popup-course #popup-header .lp-button-back button::after {
  border: 1px solid #ddd;
}

#popup-course #popup-sidebar {
  overflow: auto;
  position: relative;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 475px;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -ms-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}

#popup-course #popup-sidebar .search-course {
  display: flex;
  position: relative;
  height: 70px;
  background: #f9fafc;
}

#popup-course #popup-sidebar .search-course input[name="s"] {
  display: block;
  width: 100%;
  margin: 6px 0;
  padding-left: 20px;
  border: none;
  color: #999;
  background: transparent;
  box-shadow: none;
  font-size: 14px;
  line-height: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#popup-course
  #popup-sidebar
  .search-course
  input[name="s"]::-webkit-input-placeholder {
  color: #999;
}

#popup-course #popup-sidebar .search-course input[name="s"]::-moz-placeholder {
  color: #999;
}

#popup-course
  #popup-sidebar
  .search-course
  input[name="s"]:-ms-input-placeholder {
  color: #999;
}

#popup-course #popup-sidebar .search-course input[name="s"]:-moz-placeholder {
  color: #999;
}

#popup-course #popup-sidebar .search-course input[name="s"]::placeholder {
  color: #999;
}

#popup-course
  #popup-sidebar
  .search-course
  input[name="s"]::-webkit-input-placeholder {
  font-style: italic;
}

#popup-course #popup-sidebar .search-course input[name="s"]::-moz-placeholder {
  font-style: italic;
}

#popup-course
  #popup-sidebar
  .search-course
  input[name="s"]:-ms-input-placeholder {
  font-style: italic;
}

#popup-course #popup-sidebar .search-course input[name="s"]:-moz-placeholder {
  font-style: italic;
}

#popup-course #popup-sidebar .search-course input[name="s"]::placeholder {
  font-style: italic;
}

#popup-course #popup-sidebar .search-course input[name="s"]:focus {
  outline: 0;
  color: #222;
}

#popup-course #popup-sidebar .search-course button {
  position: absolute;
  top: 0;
  right: 10px;
  width: 16px;
  height: 70px;
  margin: 0 10px;
  padding: 0;
  border: 0;
  background: transparent;
  line-height: 1px;
}

#popup-course #popup-sidebar .search-course button::before {
  color: #666;
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 900;
  content: "\f002";
}

#popup-course #popup-sidebar .search-course button.clear {
  display: none;
}

#popup-course #popup-sidebar .search-course button.clear::before {
  content: "\f00d";
}

#popup-course #popup-sidebar .search-course .search-results {
  display: none;
  overflow: auto;
  position: fixed;
  z-index: 99999999;
  top: 92px;
  bottom: 0;
  left: 0;
  width: 400px;
  margin: 0;
  border-top-color: transparent;
  border-right: 1px solid #d9e0f1;
  background: #fbfcff;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__item {
  border-bottom: 1px solid #d9e0f1;
  background: #fff;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__item
  img {
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__item
  a {
  display: -webkit-box;
  overflow: hidden;
  position: relative;
  box-sizing: content-box;
  height: 40px;
  padding: 10px 10px 10px 60px;
  color: #777;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__item:hover {
  background: #d9e0f1;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__item-title {
  overflow: hidden;
  margin: 0 0 -2px 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__item-author {
  font-size: 12px;
}

#popup-course #popup-sidebar .search-course .search-results .course-price {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .course-price
  .origin-price {
  font-size: smaller;
  text-decoration: line-through;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__not-found {
  padding: 10px;
  color: #777;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__pagination {
  padding: 10px;
  text-align: center;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__pagination
  a,
#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__pagination
  span {
  display: inline-block;
  margin: 0 3px 10px 2px;
  padding: 5px 8px;
  border: 1px solid #d9e0f1;
  border-radius: 4px;
  color: #777;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
}

#popup-course
  #popup-sidebar
  .search-course
  .search-results
  .search-results__pagination
  span {
  color: #999;
  background: #d9e0f1;
}

#popup-course #popup-sidebar .search-course.searching .search-results {
  display: block;
}

#popup-course #popup-sidebar .search-course.searching .clear {
  display: block;
}

#popup-course #popup-sidebar .search-course.searching button {
  display: none;
}

#popup-course #popup-sidebar .search-course.searching::after {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -10px;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  content: "";
}

#popup-course #popup-sidebar .course-curriculum {
  overflow: auto;
  position: absolute;
  top: 70px;
  bottom: 0;
  width: 475px;
}

#popup-course #popup-sidebar .course-curriculum .section {
  position: relative;
  padding: 0 0 4px 0;
}

#popup-course #popup-sidebar .course-curriculum .section .circle-progress {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  border: 3px solid #d9e0f1;
  border-radius: 50%;
}

#popup-course #popup-sidebar .course-curriculum .section .section-header {
  position: sticky;
  z-index: 1000;
  top: 0;
  height: 94px;
  padding: 38px 20px 20px 20px;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-header
  .section-left
  .section-title {
  padding: 0 0 4px 0;
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-header
  .section-left
  .section-title
  .show-desc::before {
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 900;
  content: "\f0d7";
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-header
  .section-left
  .section-toggle {
  justify-content: flex-end;
  flex: 0;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-header
  .section-left
  .section-toggle
  i {
  color: #666;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-header
  .section-meta {
  padding-top: 6px;
  padding-bottom: 0;
}

#popup-course #popup-sidebar .course-curriculum .section .section-content {
  margin-bottom: 0;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item {
  height: 60px;
  margin-bottom: 4px;
  padding: 0 20px;
  background: #f9fafc;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  > span {
  display: none;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  .section-item-link {
  height: 60px;
  line-height: 1;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  .section-item-link::before {
  padding: 12px 0;
  vertical-align: unset;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  .section-item-link
  .item-name {
  display: block;
  width: 100%;
  padding: 0 10px 0 17px;
  font-size: 14px;
  font-weight: 500;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  .section-item-link
  .course-item-meta {
  display: block;
  width: 100%;
  padding: 6px 0 0 0;
  text-align: left;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  .section-item-link
  .course-item-meta
  .item-meta {
  padding: 0;
  color: #999;
  background: transparent;
  font-size: 12px;
  line-height: 1.5;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  .section-item-link
  .course-item-meta
  .item-meta:first-child {
  margin-left: 0;
  padding-left: 17px;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  .section-item-link
  .course-item-meta
  .item-meta::before {
  font-size: 12px;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item
  .section-item-link
  .course-item-meta
  .item-meta.course-item-status {
  position: absolute;
  top: 50%;
  right: 18px;
  margin-top: -10px;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item::before {
  top: -1px;
  bottom: -1px;
  height: auto;
  background: transparent;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item::after {
  content: "";
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item.current {
  background: #eaeaea;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item.item-preview
  .section-item-link
  .course-item-meta
  .item-meta::before {
  color: #999;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item.has-status.failed
  .section-item-link
  .course-item-meta
  .trans {
  color: #f02425;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section
  .section-content
  .course-item.status-completed
  .section-item-link
  .course-item-meta
  .trans {
  color: #3bb54a;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section.section-empty
  .section-header {
  margin: 0;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section.section-empty
  .learn-press-message {
  margin: 0;
  padding: 10px;
  border-bottom: 1px solid #d9e0f1;
  border-radius: 0;
  color: #999;
  background: #f5f5f5;
  font-size: 14px;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section.section-empty
  .learn-press-message::before,
#popup-course
  #popup-sidebar
  .course-curriculum
  .section.section-empty
  .learn-press-message::after {
  position: absolute;
  z-index: 9999;
  top: -16px;
  left: 10px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #f5f5f5;
  background: transparent;
  content: "";
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

#popup-course
  #popup-sidebar
  .course-curriculum
  .section.section-empty
  .learn-press-message::after {
  z-index: 9998;
  top: -20px;
  left: 8px;
  border-width: 10px;
  border-bottom-color: #d9e0f1;
}

#popup-course #popup-content {
  overflow: auto;
  position: relative;
  margin: 70px 0 50px 0;
  flex: 1;
}

#popup-course #popup-content .lp-button {
  position: relative;
  margin: 0 10px 0 0;
  padding: 12px 29px;
  border-color: var(--lp-secondary-color);
  font-size: 14px;
}

#popup-course #popup-content .lp-button.instant-check .instant-check__icon {
  margin-right: 5px;
}

#popup-course
  #popup-content
  .lp-button.instant-check
  .instant-check__icon::before {
  font-family: "Font Awesome 5 Free";
  content: "\f058";
}

#popup-course #popup-content .lp-button.instant-check .instant-check__info {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 3px;
  padding: 11px 20px;
  border-radius: 3px;
  background: #eee;
  white-space: nowrap;
  text-transform: none;
}

#popup-course
  #popup-content
  .lp-button.instant-check:hover
  .instant-check__info {
  visibility: visible;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 3px;
  padding: 11px 11px;
  border-radius: 3px;
  color: #333;
  background: #eee;
  white-space: nowrap;
}

#popup-course
  #popup-content
  .lp-button.instant-check.loading
  .instant-check__icon {
  display: inline-block;
  animation: lp-rotating 1s linear infinite;
}

#popup-course
  #popup-content
  .lp-button.instant-check.loading
  .instant-check__icon::before {
  font-weight: 800;
  content: "\f110";
}

#popup-course #popup-content .lp-button:hover {
  border-color: var(--lp-primary-color);
}

#popup-course #popup-content .lp-button.completed {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 32px;
  border-color: transparent;
  color: #fff;
  background: #3db748;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

#popup-course #popup-content .lp-button.completed i {
  margin-left: 9px;
  font-size: 14px;
}

#popup-course #popup-content #learn-press-content-item .content-item-wrap {
  width: 792px;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 0 80px 0;
}

@media (max-width: 1024px) {
  #popup-course #popup-content #learn-press-content-item .content-item-wrap {
    width: 100%;
  }
}

#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .course-item-title {
  margin-top: 0;
  margin-bottom: 24px;
  color: #333;
  font-size: 30px;
  font-weight: 600;
}

@media (max-width: 1024px) {
  #popup-course
    #popup-content
    #learn-press-content-item
    .content-item-wrap
    .content-item-summary
    .course-item-title {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  #popup-course
    #popup-content
    #learn-press-content-item
    .content-item-wrap
    .content-item-summary
    .course-item-title {
    text-align: center;
  }
}

#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .content-item-description
  h2,
#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .content-item-description
  h3,
#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .content-item-description
  h4,
#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .content-item-description
  h5,
#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .content-item-description
  h6 {
  margin-top: 0;
  margin-bottom: 18px;
  font-size: 18px;
  letter-spacing: unset;
}

#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .content-item-description
  p {
  margin: 0 0 18px 0;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

@media (max-width: 1024px) {
  #popup-course
    #popup-content
    #learn-press-content-item
    .content-item-wrap
    .content-item-summary
    .content-item-description
    p {
    margin-left: 20px;
    margin-right: 20px;
  }
}

#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .content-item-description.lesson-description {
  margin: 0 20px;
}

#popup-course #popup-content #learn-press-quiz-app {
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 1024px) {
  #popup-course #popup-content #learn-press-quiz-app {
    margin-left: 40px;
    margin-right: 40px;
  }
}

#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .course-item-title.question-title {
  margin-left: 20px;
  margin-right: 20px;
}

#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .course-item-title.quiz-title {
  margin-left: 20px;
  margin-right: 20px;
}

#popup-course
  #popup-content
  #learn-press-content-item
  .content-item-wrap
  .content-item-summary
  .content-item-description
  img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

#popup-course #popup-content #learn-press-quiz-app {
  margin-bottom: 40px;
}

#popup-course #popup-content #learn-press-quiz-app .quiz-content {
  margin-bottom: 40px;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

#popup-course #popup-content #learn-press-quiz-app .quiz-content img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

#popup-course #popup-content #learn-press-quiz-app .quiz-buttons {
  display: flex;
  justify-content: center;
  text-align: center;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons
  .button-right
  .lp-button {
  margin: 0 0 0 15px;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons
  .lp-button[data-type="question-nav"] {
  margin: 0;
  border: 1px solid #d9e0f1;
  color: #777;
  background: #fbfcff;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons
  .lp-button[data-type="question-nav"]::before {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid #d9e0f1;
  border-top-color: transparent;
  border-left-color: transparent;
  content: "";
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons
  .lp-button[data-type="question-nav"].prev::before {
  margin-left: 7px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons
  .lp-button[data-type="question-nav"].next {
  margin-left: -1px;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons
  .lp-button[data-type="question-nav"].next::before {
  margin-right: 7px;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons
  .lp-button[data-type="question-nav"]:hover {
  background: #fff;
}

#popup-course #popup-content #learn-press-quiz-app .quiz-buttons.align-center {
  display: block;
  text-align: center;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons.align-center
  .button-left {
  text-align: center;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons.align-center
  .button-left.fixed {
  position: fixed;
  z-index: 99999;
  bottom: 0;
  left: 50%;
  width: 792px;
  height: 50px;
  margin-left: 237px;
  transform: translateX(-50%);
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons.align-center
  .button-left.nav-center {
  height: 50px;
  margin-left: 0 !important;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons:not(.infinity).is-first
  .prev {
  display: none;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons:not(.infinity).is-last
  .next {
  display: none;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons:not(.is-first):not(.is-last)
  .prev {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .quiz-buttons:not(.is-first):not(.is-last)
  .next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#popup-course #popup-content #learn-press-quiz-app .questions-pagination {
  height: 33px;
  margin-top: 1px;
  padding: 8px 0;
  background: #fff;
  text-align: center;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .questions-pagination
  .nav-links {
  text-align: center;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .questions-pagination
  .nav-links
  .page-numbers {
  display: inline-block;
  margin: 0;
  padding: 10px 16px;
  color: #666;
  background-color: transparent;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  cursor: pointer;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .questions-pagination
  .nav-links
  .page-numbers.next,
#popup-course
  #popup-content
  #learn-press-quiz-app
  .questions-pagination
  .nav-links
  .page-numbers.prev {
  display: inline-block;
  padding: 8px;
  background: transparent;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}

#popup-course
  #popup-content
  #learn-press-quiz-app
  .questions-pagination
  .nav-links
  .page-numbers.current,
#popup-course
  #popup-content
  #learn-press-quiz-app
  .questions-pagination
  .nav-links
  .page-numbers:hover {
  color: var(--lp-primary-color);
}

#popup-course #popup-content.fixed-quiz-status .quiz-status {
  background: var(--lp-primary-color);
}

#popup-course #popup-content.fixed-quiz-status .quiz-status > div {
  padding: 0;
}

#popup-course #popup-footer {
  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
  left: 475px;
  width: 792px;
  height: 50px;
  margin: 0 auto;
  border-top: 1px solid #ebebeb;
  background: #fff;
  -webkit-transition: left 0.25s;
  -moz-transition: left 0.25s;
  -ms-transition: left 0.25s;
  -o-transition: left 0.25s;
  transition: left 0.25s;
}

@media (max-width: 1024px) {
  #popup-course #popup-footer {
    width: 100%;
    padding: 0 15px;
  }
}

#popup-course #popup-footer .course-item-nav {
  justify-content: space-between;
}

#popup-course #popup-footer .course-item-nav .prev,
#popup-course #popup-footer .course-item-nav .next {
  display: flex;
  line-height: 50px;
}

#popup-course #popup-footer .course-item-nav .prev a,
#popup-course #popup-footer .course-item-nav .next a {
  display: block;
  color: #666;
  font-size: 16px;
  font-weight: 300;
}

#popup-course #popup-footer .course-item-nav .prev::before,
#popup-course #popup-footer .course-item-nav .next::before {
  color: #999;
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 900;
}

#popup-course #popup-footer .course-item-nav .prev:hover a,
#popup-course #popup-footer .course-item-nav .prev:hover::before,
#popup-course #popup-footer .course-item-nav .next:hover a,
#popup-course #popup-footer .course-item-nav .next:hover::before {
  color: var(--lp-primary-color);
}

#popup-course #popup-footer .course-item-nav .next {
  flex-direction: row-reverse;
}

#popup-course #popup-footer .course-item-nav .next::before {
  margin-left: 10px;
  content: "\f0da";
}

#popup-course #popup-footer .course-item-nav .prev::before {
  margin-right: 10px;
  content: "\f0d9";
}

#popup-course #popup-footer .course-item-nav .prev .course-item-nav__name {
  right: auto;
  left: -30px;
}

#popup-course #popup-footer .course-item-nav .course-item-nav__name {
  display: none;
  position: absolute;
  top: -20px;
  right: -30px;
  width: auto;
  padding: 10px 15px;
  color: #666;
  background: #ccc;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
}

#popup-course #popup-footer .course-item-nav .next:hover .course-item-nav__name,
#popup-course
  #popup-footer
  .course-item-nav
  .prev:hover
  .course-item-nav__name {
  display: block;
}

#popup-course
  #popup-footer
  .course-item-nav
  .prev
  .course-item-nav__name::before {
  right: auto;
  left: 5px;
}

#popup-course #popup-footer .course-item-nav[data-nav="next"] {
  justify-content: flex-end;
}

#popup-course #sidebar-toggle {
  display: inline-block;
  position: absolute;
  z-index: 100;
  top: 198px;
  left: 475px;
  width: 25px;
  height: 56px;
  margin: 0;
  background: #fff;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.25s;
  -webkit-appearance: none;
  border: none;
}

#popup-course #sidebar-toggle:after {
  display: none;
}

#popup-course #sidebar-toggle::before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--lp-primary-color);
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  font-weight: 900;
  content: "\f0d9";
  transition: 0.25s;
  transform: translate(-50%, -50%);
}

#popup-course #sidebar-toggle:focus {
  border: 0;
  outline: 0;
}

#popup-course .lp-quiz-buttons .complete-quiz,
#popup-course .lp-quiz-buttons .back-quiz,
#popup-course .lp-quiz-buttons .review-quiz {
  float: right;
}

#popup-course .quiz-results,
#popup-course .quiz-content,
#popup-course .quiz-questions,
#popup-course .quiz-buttons,
#popup-course .quiz-attempts {
  margin-bottom: 60px;
}

#popup-course .quiz-result .result-grade {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  height: 200px;
  margin-bottom: 30px;
  padding: 50px;
  justify-content: center;
  align-items: center;
}

#popup-course .quiz-result .result-grade::before,
#popup-course .quiz-result .result-grade svg {
  position: absolute;
  top: 0;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-left: -100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#popup-course .quiz-result .result-grade::before {
  box-sizing: border-box;
  padding: 30px;
  border: 10px solid #ccc;
  content: "";
}

#popup-course .quiz-result .result-grade svg {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#popup-course .quiz-result .result-grade svg circle {
  stroke: var(--lp-primary-color);
}

#popup-course .quiz-result.passed .result-grade svg {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#popup-course .quiz-result.passed .result-grade .result-achieved {
  color: #333;
}

#popup-course .quiz-result.passed .result-grade .result-message strong {
  color: #4caf50;
}

#popup-course .quiz-questions .question {
  margin-bottom: 60px;
}

#popup-course .quiz-questions .lp-fib-content {
  margin-bottom: 20px;
  padding: 20px;
  border: 2px solid #f5f5f5;
  border-radius: 6px;
  line-height: 1.6;
}

#popup-course .quiz-questions .lp-fib-content .lp-fib-input {
  display: inline-block;
  width: auto;
  max-width: none;
}

#popup-course .quiz-questions .lp-fib-content .lp-fib-input > input {
  height: 36px;
  padding: 6px 16px;
  border: 1px solid #222;
  line-height: 36px;
}

#popup-course .quiz-questions .lp-fib-content .lp-fib-answered {
  padding: 0 10px;
  background: #ececec;
  white-space: nowrap;
}

#popup-course .quiz-questions .lp-fib-content .lp-fib-answered.fail {
  border: 2px solid #d85554;
}

#popup-course
  .quiz-questions
  .lp-fib-content
  .lp-fib-answered.fail
  .lp-fib-answered__answer {
  text-decoration: line-through;
}

#popup-course .quiz-questions .lp-fib-content .lp-fib-answered.correct {
  border: 2px solid #00adff;
}

#popup-course .quiz-questions .lp-fib-note {
  display: flex;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
}

#popup-course .quiz-questions .lp-fib-note > span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
}

#popup-course .quiz-questions .lp-sorting-choice__check-answer {
  padding: 5px 20px;
  border: 2px solid #059601;
  border-radius: 5px;
  font-size: 1rem;
}

#popup-course .question .answer-option {
  padding: 0;
  _border: 2px solid #d9e0f1;
  background: #fff;
}

#popup-course .question .answer-option input[type="radio"],
#popup-course .question .answer-option input[type="checkbox"] {
  position: absolute;
  top: 50%;
  margin: -19px 0 0 10px;
  border-color: #d9e0f1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#popup-course .question .answer-option input[type="radio"]::after,
#popup-course .question .answer-option input[type="checkbox"]::after {
  border-color: #d9e0f1;
}

#popup-course .question .answer-option input[type="radio"]:disabled,
#popup-course .question .answer-option input[type="checkbox"]:disabled {
  border-color: #ddd;
  background: #f9f9f9;
}

#popup-course .question .answer-option input[type="radio"]:disabled::after,
#popup-course .question .answer-option input[type="checkbox"]:disabled::after {
  border-color: #ddd;
}

#popup-course
  .question
  .answer-option
  input[type="radio"]:checked:not(:disabled)::after,
#popup-course
  .question
  .answer-option
  input[type="checkbox"]:checked:not(:disabled)::after {
  border-color: #99aee4;
}

#popup-course .question .answer-option input[type="radio"] {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#popup-course .question .answer-option input[type="radio"]::before {
  border-radius: 50%;
}

#popup-course .question .answer-option .option-title {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 14px 10px 11px 65px;
  border: 1px solid #ccc;
  color: #666;
  background: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#popup-course .question .answer-option .option-title::after {
  display: inline-block;
  visibility: hidden !important;
  content: ".";
}

#popup-course .question .answer-option .option-title:hover {
  background: #f9f9f9;
}

#popup-course .question .answer-option .option-title::before {
  display: none;
}

#popup-course .question .answer-option.answer-correct .option-title {
  border-color: #4caf50;
}

#popup-course .question .answer-option.answered-wrong .option-title {
  border-color: #ff6423;
}

#popup-course .question .question-title {
  display: block;
  margin-top: 0;
  margin-bottom: 18px;
  color: #333;
  font-size: 18px;
  font-weight: normal;
  font-weight: 500;
}

#popup-course .question .question-title .edit-link {
  float: right;
  font-size: 14px;
  font-weight: normal;
}

#popup-course .question .question-content {
  margin-bottom: 30px;
  color: #666;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
}

#popup-course .question .question-response {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
}

#popup-course .question .question-response .label {
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 8px 10px;
  border-radius: 4px;
  color: #fff;
  line-height: 1;
}

#popup-course .question .question-response .point {
  display: inline-block;
}

#popup-course .question .question-response.correct .label {
  background: #4caf50;
}

#popup-course .question .question-response.incorrect .label {
  background: #ff6423;
}

#popup-course .question .question-index {
  display: inline-block;
  margin: 0 5px 0 0;
}

#popup-course .question .btn-show-hint {
  position: relative;
  margin: 0 0 0 10px;
  padding: 0;
  border: none;
  outline: none;
  color: #00adff;
  background: transparent;
  font-size: 0;
  text-decoration: none;
}

#popup-course .question .btn-show-hint::before {
  font-family: "Font Awesome 5 Free";
  font-size: 18px;
  content: "\f059";
}

#popup-course .question .btn-show-hint:hover span {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: auto;
  padding: 6px 9px;
  border-radius: 2px;
  color: #333;
  background: #eee;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  text-transform: none;
}

#popup-course .question.question-fill_in_blanks .blanks .blank-input-wrap {
  display: inline-block;
}

#popup-course .question.question-fill_in_blanks .blanks .blank-block {
  margin-bottom: 20px;
}

#popup-course .question.question-fill_in_blanks .blanks .blank-input {
  display: inline-block;
  width: auto;
  min-width: 50px;
  margin: 0 10px;
  padding: 0 10px;
  border: none;
  border-bottom: 1px dashed #ddd;
  text-align: center;
  white-space: nowrap;
}

#popup-course .question.question-fill_in_blanks .blanks .blank-input br {
  display: none;
}

#popup-course .question.question-fill_in_blanks .blanks .blank-input > * {
  display: inline;
  white-space: nowrap;
}

#popup-course .question.question-fill_in_blanks .blanks .blank-select {
  display: inline-block;
  height: 30px;
  margin-left: 4px;
  padding: 1px;
}

#popup-course .question.question-fill_in_blanks .blanks .blank-fills {
  display: inline-block;
}

#popup-course .question.question-fill_in_blanks .blanks .blank-fills code {
  margin: 0 5px;
  padding: 0 6px;
  background: #ddd;
  line-height: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#popup-course .question.question-fill_in_blanks .blanks.ordered {
  list-style-position: inside;
}

#popup-course .question.question-fill_in_blanks .blanks.one-paragraph {
  line-height: 3;
}

#popup-course .scrollbar-light > .scroll-element {
  z-index: 9999;
}

#popup-course .scrollbar-light > .scroll-element.scroll-y {
  display: none;
}

#popup-course .scrollbar-light > .scroll-element.scroll-y .scroll-bar {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body.admin-bar #popup-course {
  top: 32px;
}

body.lp-sidebar-toggle__close #popup-course > #sidebar-toggle {
  left: 0;
}

body.lp-sidebar-toggle__close #popup-course > #sidebar-toggle::before {
  content: "\f0da";
}

body.lp-sidebar-toggle__close #popup-course #popup-sidebar {
  flex: 0 0 0;
}

body.lp-sidebar-toggle__close #popup-course #popup-header,
body.lp-sidebar-toggle__close #popup-course #popup-footer {
  left: 0;
}

@media (max-width: 768px) {
  body:not(.lp-sidebar-toggle__open) #popup-course > #sidebar-toggle {
    left: 0;
  }
  body:not(.lp-sidebar-toggle__open) #popup-course > #sidebar-toggle::before {
    content: "\f0da";
  }
  body:not(.lp-sidebar-toggle__open) #popup-course #popup-sidebar {
    flex: 0 0 0;
  }
  body:not(.lp-sidebar-toggle__open) #popup-course #popup-header,
  body:not(.lp-sidebar-toggle__open) #popup-course #popup-footer {
    left: 0;
  }
}

.course-curriculum .section-header .section-meta {
  position: relative;
}

@media screen and (max-width: 1280px) {
  #popup-course #popup-sidebar {
    flex-basis: 300px;
  }
  #popup-course #popup-sidebar .course-curriculum {
    width: 300px;
  }
  #popup-course #popup-header,
  #popup-course #popup-footer {
    left: 300px;
  }
  #popup-course #sidebar-toggle {
    left: 299px;
  }
}

@media screen and (max-width: 782px) {
  body.admin-bar #popup-course {
    top: 46px;
  }
  #popup-course #popup-sidebar {
    flex-basis: 250px;
  }
  #popup-course #popup-sidebar .course-curriculum {
    width: 250px;
  }
  #popup-course #popup-header,
  #popup-course #popup-footer {
    left: 250px;
  }
  #popup-course #sidebar-toggle {
    left: 249px;
  }
  #popup-course #sidebar-toggle:checked ~ #popup-sidebar {
    flex-basis: 0;
  }
  #popup-course #sidebar-toggle:checked ~ #popup-footer,
  #popup-course #sidebar-toggle:checked ~ #popup-header {
    left: 0;
  }
  #popup-course .course-item-meta .item-meta.count-questions,
  #popup-course .course-item-meta .item-meta.duration {
    display: none;
  }
}

.lp-overlay {
  display: none;
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(209, 213, 219, 0.8);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-property: opacity;
}

.wp-admin .lp-modal-dialog .lp-modal-content .lp-modal-header {
  padding: 2em;
}

.wp-admin .lp-modal-dialog .lp-modal-content .lp-modal-header h3 {
  font-size: 1.4em;
}

.lp-modal-dialog {
  display: flex;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.lp-modal-dialog .lp-modal-content {
  display: inline-block;
  overflow: hidden;
  z-index: 2;
  width: auto;
  max-width: 600px;
  border-radius: 8px;
  text-align: left;
  vertical-align: middle;
  background: white;
  color: black;
}

.lp-modal-dialog .lp-modal-content .lp-modal-header {
  background: #7c60d9;
  border-bottom: 1px solid #eee;
  padding: 1em;
}

.lp-modal-dialog .lp-modal-content .lp-modal-header h3 {
  margin: 0;
  color: white;
  font-size: inherit;
}

.lp-modal-dialog .lp-modal-content .lp-modal-body .main-content {
  max-height: 500px;
  overflow: auto;
}

.lp-modal-dialog .lp-modal-content .lp-modal-body .main-content h3,
.lp-modal-dialog .lp-modal-content .lp-modal-body .main-content h2,
.lp-modal-dialog .lp-modal-content .lp-modal-body .main-content .pd-2em {
  padding: 0 2em;
}

.lp-modal-dialog .lp-modal-header {
  padding: 0 20px;
}

.lp-modal-dialog .lp-modal-footer {
  padding: 10px 20px;
  background-color: #f9fafb;
  text-align: right;
}

.lp-modal-dialog .btn-yes {
  color: #fff;
  background-color: #7c60d9;
}

#lp-modal-overlay {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background: #000;
}

#lp-modal-window {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  padding: 35px 60px 28px 60px;
  background: #fff;
  transform: translate(-50%, -50%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#lp-modal-window #lp-modal-content {
  margin: 0 0 24px 0;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

#lp-modal-window #lp-modal-content > * {
  margin: 0 0 0.5em;
}

#lp-modal-window #lp-modal-content #lp-modal-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

#lp-modal-window #lp-modal-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

#lp-modal-window #lp-modal-buttons .lp-button {
  position: relative;
  margin: 0 10px 0 10px;
}

.learn-press-comments {
  width: 792px;
  max-width: 100%;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .learn-press-comments {
    width: 100%;
  }
}

.learn-press-progress {
  overflow: hidden;
  position: relative;
  width: 80px;
  height: 6px;
  border-radius: 3px;
}

.learn-press-progress .learn-press-progress__active {
  position: absolute;
  z-index: 1;
  left: -100%;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: var(--lp-primary-color);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.learn-press-progress::before {
  display: block;
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ccc;
  content: "";
}

.course-curriculum .section-header .section-left {
  display: flex;
}

.course-curriculum .section-header .section-left .section-title {
  flex: 1;
}

.course-curriculum .section-header .section-left .section-toggle {
  flex: 0 0 40px;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.course-curriculum
  .section-header
  .section-left
  .section-toggle
  .fa-caret-down {
  display: none;
}

.course-curriculum .section-header .learn-press-progress {
  width: 80px;
}

.course-curriculum .section.closed .section-toggle .fa-caret-down {
  display: block;
}

.course-curriculum .section.closed .section-toggle .fa-caret-up {
  display: none;
}

.course-curriculum .section.closed .section-content {
  overflow: hidden;
  height: 0;
}

.lp-skeleton-animation {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lp-skeleton-animation > li {
  width: 100%;
  height: 16px;
  margin-top: 15px;
  border-radius: 2px;
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;
  list-style: none;
  animation: lp-skeleton-loading 1.4s ease infinite;
}

@keyframes lp-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  box-shadow: unset;
}

/*  start reset css */
body {
  background: #fff;
}

body.learnpress-page {
  overflow-x: hidden;
}

.wrap-fullwidth {
  margin-right: -1000px;
  margin-left: -1000px;
}

.wrap-fullwidth a:focus,
.wrap-fullwidth button:focus,
.wrap-fullwidth input:focus,
.wrap-fullwidth textarea:focus {
  border: 0;
  outline: 0;
}

.wrap-fullwidth a:hover {
  text-decoration: none;
}

.wrap-fullwidth p {
  word-break: break-word;
}

.wrap-fullwidth p:last-child {
  margin-bottom: 0;
}

.wrap-fullwidth label {
  display: block;
  margin: 0;
  outline: none;
}

.wrap-fullwidth img {
  max-width: 100%;
  height: auto;
}

.wrap-fullwidth form {
  margin: 0;
}

.wrap-fullwidth .col-full {
  box-sizing: border-box;
  max-width: 100%;
  padding: 0;
}

.wrap-fullwidth .col-full .woocommerce-breadcrumb {
  display: none;
}

/*  end reset css */
footer {
  clear: both;
}

.margin-bottom {
  margin-bottom: 20px;
}

.hide-if-js {
  display: none !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.lp-form-fields {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lp-form-fields .form-field {
  margin: 0 0 20px 0;
  list-style: none;
}

.lp-content-wrap {
  width: 1170px;
  max-width: 90%;
  margin: 0 auto;
}

.lp-content-wrap > h2 {
  margin-top: 0;
  margin-bottom: 26px;
  color: #333;
  font-size: 30px;
  font-weight: 600;
}

.learn-press-breadcrumb {
  width: 1170px;
  max-width: 100%;
  margin: 20px auto 45px auto;
  padding: 0;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  list-style: none;
}

.learn-press-breadcrumb li {
  display: inline-block;
  margin: 0;
}

.learn-press-breadcrumb li a {
  color: #666;
  font-weight: 300;
}

.learn-press-breadcrumb li a:hover {
  color: var(--lp-primary-color);
}

.learn-press-breadcrumb i {
  margin: 0 5px;
  color: #666;
  font-size: 12px;
}

@media (max-width: 1024px) {
  .learn-press-breadcrumb {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.learn-press-breadcrumb a {
  color: #666;
  font-weight: 300;
}

.lp-archive-courses .lp-button,
.lp-archive-courses #lp-button {
  padding: 16px 24px;
  border: 1px solid #ccc;
  color: #333;
  background: transparent;
  box-shadow: unset;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  text-transform: capitalize;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.lp-archive-courses .lp-button.large,
.lp-archive-courses #lp-button.large {
  height: 52px;
  padding: 18px 30px;
  font-size: 18px;
}

.lp-archive-courses .lp-button.gray,
.lp-archive-courses #lp-button.gray {
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 5px;
  padding: 0;
  border: 0;
}

.lp-archive-courses .lp-button.gray:hover,
.lp-archive-courses #lp-button.gray:hover {
  background: transparent;
  text-decoration: none;
}

.lp-archive-courses .lp-button.gray:focus,
.lp-archive-courses #lp-button.gray:focus {
  border: 0;
  outline: 0;
}

.lp-archive-courses .lp-button:hover,
.lp-archive-courses #lp-button:hover {
  border-color: var(--lp-primary-color);
  color: #fff;
  background: var(--lp-primary-color);
}

.lp-archive-courses .lp-button.btn-ajax-off .icon,
.lp-archive-courses #lp-button.btn-ajax-off .icon {
  display: none;
}

.lp-archive-courses .lp-button.btn-ajax-on .icon,
.lp-archive-courses #lp-button.btn-ajax-on .icon {
  display: inline-block;
  margin-right: 5px;
  -webkit-animation: lp-rotating 1s linear infinite;
  -moz-animation: lp-rotating 1s linear infinite;
  animation: lp-rotating 1s linear infinite;
}

.lp-archive-courses .lp-button:focus,
.lp-archive-courses #lp-button:focus {
  outline: 0;
}

.lp-archive-courses .lp-button.loading::before,
.lp-archive-courses #lp-button.loading::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f110";
  animation: lp-rotating 1s linear infinite;
}

.learnpress-page .rwmb-field .description {
  margin-top: 8px;
  color: #999;
  font-size: smaller;
  font-style: italic;
}

.lp-ajax-message {
  display: none;
  margin-top: 20px;
  padding: 20px;
  border: 2px solid #059601;
  border-radius: 9px;
  font-size: 15px;
  line-height: 1.4;
}

.lp-ajax-message.error {
  border-color: #d85554;
}

.course-meta {
  margin-bottom: 14px;
}

.course-meta .course-students::before {
  font-family: "Font Awesome 5 Free";
  content: "";
}

#learn-press-course-tabs {
  margin-bottom: 40px;
}

#learn-press-course-tabs.course-tabs {
  margin-top: 54px;
}

/* Archive */
.learn-press-checkout-comment h4 {
  display: none;
}

.order-comments {
  width: 100%;
  min-height: 150px;
}

.learn-press-progress {
  position: relative;
}

.learn-press-progress .progress-bg {
  overflow: hidden;
  position: relative;
  height: 6px;
  background: #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.learn-press-progress .progress-bg .progress-active {
  position: absolute;
  left: 50%;
  width: 100%;
  height: 100%;
  margin-left: -100%;
  background: var(--lp-primary-color);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/**
 * Forms
 */
.retake-course,
.enroll-course,
.purchase-course {
  display: inline-block;
}

/* */
.table-orders {
  font-size: 16px;
}

.table-orders th,
.table-orders td {
  padding: 5px 10px;
}

.form-button {
  display: inline-block;
}

.learn-press-pagination {
  text-align: center;
}

.learn-press-pagination .page-numbers {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  list-style: none;
}

.learn-press-pagination .page-numbers > li {
  display: inline-block;
  margin: 0;
  padding: 0 14px;
}

.learn-press-pagination .page-numbers > li .page-numbers {
  float: unset;
  padding: 0;
  color: #666;
  background: transparent;
  font-size: 16px;
  font-weight: 300;
}

.learn-press-pagination .page-numbers > li .page-numbers.current {
  color: var(--lp-primary-color);
  font-weight: 400;
}

.learn-press-pagination .page-numbers > li .page-numbers:hover {
  color: var(--lp-primary-color);
}

ul.list-table-nav {
  display: flex;
  margin-left: 0;
  list-style: none;
}

ul.list-table-nav .nav-text {
  text-align: left;
  flex: 1;
}

ul.list-table-nav .nav-pages {
  text-align: right;
  flex: 1;
}

ul.list-table-nav .nav-pages .learn-press-pagination {
  display: inline-block;
  margin-bottom: 0;
}

.primary-color {
  color: #00adff;
}

.primary-color-before::before {
  color: #00adff;
}

.primary-color-after::after {
  color: #00adff;
}

.primary-background-color {
  background: #00adff;
}

.primary-background-color {
  background: #00adff;
}

.course-origin-price {
  margin-right: 5px;
  font-size: 85%;
  text-decoration: line-through;
}

.course-item-nav {
  display: flex;
}

.course-item-nav .prev,
.course-item-nav .next {
  /* flex: 1;*/
}

.course-item-nav .prev span,
.course-item-nav .next span {
  display: block;
  font-weight: bold;
}

.course-item-nav .prev a,
.course-item-nav .next a {
  color: #999;
}

.course-item-nav .next {
  text-align: right;
}

.content-item-wrap #comments {
  margin-right: 0;
  margin-left: 0;
}

.content-item-wrap #comments #comment {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.course-summary .course-summary-sidebar {
  position: relative;
}

.course-summary
  .course-summary-sidebar.slide-down
  .course-summary-sidebar__inner {
  position: fixed;
  top: 0;
}

.course-summary
  .course-summary-sidebar.slide-top
  .course-summary-sidebar__inner {
  position: absolute;
}

.course-graduation span {
  color: #999;
}

.course-graduation .icon {
  margin-right: 5px;
  font-size: 20px;
}

.course-graduation.passed .icon {
  color: #059601;
}

.course-graduation.error .icon {
  color: #d85554;
}

.learn-press-template-warning::before {
  color: #ffc107;
  font-family: "Font Awesome 5 Free";
  content: "\f071";
}

.lp-badge {
  display: flex;
  height: 30px;
  padding: 0;
  line-height: 1;
  align-items: center;
  justify-content: center;
}

.lp-badge::before,
.lp-badge::after {
  content: "";
}

.lp-badge::before {
  display: inline-block;
  position: relative;
  z-index: 10;
  color: #fff;
  font-weight: bold;
  line-height: 1;
}

.lp-badge.featured-course {
  position: absolute;
  z-index: 1;
  top: 25px;
  left: -110px;
  width: 300px;
  background: #93f;
  text-align: center;
  transform: rotate(-45deg);
}

.lp-badge.featured-course::before {
  font-size: 12px;
  text-transform: uppercase;
  content: attr(data-text);
}

#learn-press-course-tabs input[name="learn-press-course-tab-radio"],
#learn-press-course-tabs .course-tab-panel {
  display: none;
}

#learn-press-course-tabs .course-nav label {
  display: block;
  margin: 0;
  padding: 18px 10px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(1):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(1) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(1):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(1) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(1):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(1)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(2):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(2) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(2):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(2) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(2):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(2)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(3):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(3) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(3):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(3) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(3):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(3)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(4):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(4) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(4):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(4) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(4):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(4)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(5):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(5) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(5):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(5) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(5):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(5)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(6):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(6) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(6):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(6) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(6):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(6)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(7):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(7) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(7):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(7) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(7):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(7)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(8):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(8) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(8):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(8) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(8):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(8)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(9):checked
  ~ .course-tab-panels
  .course-tab-panel:nth-child(9) {
  display: block;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(9):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(9) {
  border-bottom: 0;
}

#learn-press-course-tabs
  input[name="learn-press-course-tab-radio"]:nth-child(9):checked
  ~ .learn-press-nav-tabs
  .course-nav:nth-child(9)
  label {
  color: var(--lp-primary-color);
  background: #fff;
}

.no-event {
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  ul.learn-press-courses .course {
    width: 48%;
  }
}

@media screen and (max-width: 600px) {
  ul.learn-press-courses {
    margin-right: 0;
  }
  ul.learn-press-courses .course {
    width: 100%;
    margin-right: 0;
  }
}
