body {
  font-family: "Poppins", sans-serif !important;
  padding: 0;
  margin: 0;
  font-size: 16px;
  --lp-primary-color: #2572ff;
  --lp-secondary-color: #442e66;
}

a:hover {
  text-decoration: none;
}

a {
  text-decoration: none !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.subtitle {
  color: rgb(0, 100, 146);
  font-size: 30px;
  margin-top: 12px;
}

.points li {
  margin-bottom: 12px;
}

.sub-header {
  font-weight: bold;
  font-size: 20px;
}

.note {
  font-weight: bold;
  font-size: 14px;
}

p.basic-paragraph {
  font-weight: 500 !important;
}
