.featured {
  flex: 2;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 10px;
}

.featured .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
}

.featured .top .title {
  font-size: 16px;
  font-weight: 500;
}

.featured .bottom {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.featured .bottom .featuredChart {
  width: 100px;
  height: 100px;
}

.featured .bottom .title {
  font-weight: 500;
  color: gray;
}

.featured .bottom .amount {
  font-size: 30px;
}

.featured .bottom .desc {
  font-weight: 300;
  font-size: 12px;
  color: gray;
  text-align: center;
}

.featured .bottom .summary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.featured .bottom .summary .item {
  text-align: center;
}

.featured .bottom .summary .item .itemTitle {
  font-size: 14px;
  color: gray;
}

.featured .bottom .summary .item .itemResult {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}

.featured .bottom .summary .item .itemResult.positive {
  color: green;
}

.featured .bottom .summary .item .itemResult.negative {
  color: red;
}
