.single {
  display: flex;
  width: 100%;
}

.single .singleContainer {
  flex: 1;
  margin-left: 246px;
}

.singleContainer .top {
  padding: 20px;
  display: flex;
  gap: 20px;
}

.singleContainer .top .left {
  flex: 1;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  position: relative;
}

.singleContainer .top .left .editButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 12px;
  color: #7451f8;
  background-color: #7551f818;
  cursor: pointer;
  border-radius: 0px 0px 0px 5px;
}

.singleContainer .top .left .item {
  display: flex;
  gap: 20px;
}

.singleContainer .top .left .item .itemImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.singleContainer .top .left .item .details .itemTitle {
  margin-bottom: 10px;
  color: #555;
}

.singleContainer .top .left .item .details .detailItem {
  margin-bottom: 10px;
  font-size: 14px;
}

.singleContainer .top .left .item .details .detailItem .itemKey {
  font-weight: bold;
  color: gray;
  margin-right: 5px;
}

.singleContainer .top .left .item .details .detailItem .itemValue {
  font-weight: 300;
}

.singleContainer .top .right {
  flex: 2;
}

.singleContainer .bottom {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  margin: 10px 20px;
}

.singleContainer .title {
  font-size: 16px;
  color: lightgray;
  margin-bottom: 20px;
}

/* src/pages/admin/single/single.css */
.htmlContent {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  line-height: 1.6;
}

.htmlContent p {
  margin: 0 0 10px;
}

.htmlContent ul {
  padding-left: 20px;
  margin: 0;
}

.htmlContent ul li {
  list-style-type: disc;
}
