.loader {
  display: inline-block;
  border: 4px solid #f3f3f3; /* Light grey */
  border-radius: 50%;
  border-top-color: #3498db; /* Primary color */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
