.terms h1,
.privacy h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 25px;
}

.terms h2,
.privacy h2 {
  font-size: 21px;
  font-weight: 600;
}

.terms a,
.privacy a {
  color: blue;
  cursor: pointer;
}

.terms a:hover,
.privacy a:hover {
  color: red;
}

.terms span,
.privacy span {
  font-size: 14px;
}

.privacy h4 {
  font-size: 18px;
}
